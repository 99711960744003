<script>
  import {
    defineComponent,
    onMounted,
    reactive,
    toRefs,
    computed,
    ref,
    nextTick,
    onUpdated,
    watch,
  } from 'vue'
  import WaveformData from 'waveform-data'
  import { durationFormat } from '../utils/time'
  import { roundToTwo } from '../utils/math'
  import {
    useResizeObserver,
    onClickOutside,
    useWindowSize,
    useClipboard,
  } from '@vueuse/core'
  import Bowser from 'bowser'

  export default defineComponent({
    name: 'AudioPlayer',
    props: {
      src: {
        type: Array,
        required: true,
        default: () => [{ url: '', type: '', peaksUrl: '' }],
      },
      artwork: {
        type: Object,
        default: () => {
          return {
            url: 'https://images.prismic.io/storyboard-fm/6b63c11c-70aa-4c97-a7de-db68f80a4709_OnTheGo_flat.png?auto=compress,format',
            alt: 'placeholder artwork',
            width: 300,
            height: 300,
          }
        },
      },
      captions: {
        type: String,
        default: '',
      },
      playbackRange: {
        type: Object,
        default: () => {
          return { start: 0, duration: 0 }
        },
      },
      preload: {
        type: String,
        default: 'metadata',
        validator(value) {
          return ['metadata', 'auto', 'none'].includes(value)
        },
      },
      playbackTrigger: {
        type: String,
        default: 'canplaythrough',
        validator(value) {
          return ['canplay', 'canplaythrough', 'loadeddata'].includes(value)
        },
      },
      forward: {
        type: Number,
        default: 30,
        validator(value) {
          return [10, 15, 30].includes(value)
        },
      },
      backward: {
        type: Number,
        default: 30,
        validator(value) {
          return [10, 15, 30].includes(value)
        },
      },
      loop: {
        type: Boolean,
        default: false,
      },
      variation: {
        type: String,
        default: 'simple',
        validator(value) {
          return ['simple', 'podcast', 'drawer'].includes(value)
        },
      },
      drawerPlayerConfig: {
        type: Object,
        default: () => {
          return {
            offset: 0,
            shareUrl: '',
          }
        },
      },
    },
    emits: [
      'play',
      'seek',
      'paused',
      'completed',
      'loading',
      'ready',
      'progress',
      'rating',
    ],
    setup: (props, { emit, expose }) => {
      let source, rAF
      let audioContext = new AudioContext()
      let gain = audioContext.createGain()
      const audioTrack = ref(null)
      const track = ref(null)
      const progress = ref(null)
      const volumeControl = ref(null)
      const audio = ref(null)
      const audioDate = ref(null)
      const audioTitle = ref(null)
      const audioDescription = ref(null)
      const audioDescriptionOverflow = ref(null)
      const drawer = ref(null)
      const fullDrawerPlayer = ref(null)
      const drawerSubMenu = ref(null)

      const { copy, copied } = useClipboard({
        source: props.drawerPlayerConfig.shareUrl,
        copiedDuring: 3000,
      })

      useResizeObserver(track, (entries) => {
        const entry = entries[0]
        const { width, height } = entry.contentRect
        state.waveformWidth = width
        state.waveformHeight = height
      })

      useResizeObserver(audioDescriptionOverflow, (entries) => {
        const entry = entries.length > 0 ? entries[0] : null
        if (entry) {
          setAudioInfo()
        }
      })

      onClickOutside(volumeControl, () => (state.showVolumeControl = false))
      onClickOutside(drawerSubMenu, () => (state.showDrawerSubMenu = false))

      const state = reactive({
        browser: computed(() => Bowser.getParser(window.navigator.userAgent)),
        status: {
          connected: false,
          playing: false,
          ready: false,
          loading: false,
          error: false,
          seek: 0,
        },
        currentPlayback: computed(() => {
          return durationFormat(state.status.seek)
        }),
        date: '',
        description: {},
        descriptionHasOverflow: false,
        descriptionOverflowOpen: false,
        descriptionOverflowBtnText: computed(() =>
          state.descriptionOverflowOpen ? 'Read less' : 'Read more'
        ),
        duration: 0,
        fullDuration: computed(() => {
          return durationFormat(state.duration)
        }),
        hoverXCoord: 0,
        hoverPositionTime: 0,
        infoPopulated: computed(() => {
          return state.title || state.description ? true : false
        }),
        isMobileView: computed(() => state.windowSize.width < 800),
        loading: computed(
          () => state.status.loading || state.status.error || state.waiting
        ),
        source: computed(() => {
          // determine the best audio by files provided and browser
          let preferredFile = { url: '', type: '', peaksUrl: '' }
          if (props.src.length > 0) {
            // determine if the browser supports opus codecs and file types
            let isValidOpusBrowser = state.browser.satisfies({
              chrome: '>=4',
              firefox: '>=3.5',
              opera: '>=11.5',
              safari: '>=14.1',
              edge: '>=17',

              //mobile specific supported browsers
              'android browser': '>=2.3',
              'samsung_internet': '>=4',
            })

            const supportedFileTypes = ['audio/mpeg', 'audio/wav', 'audio/pcm']

            if (isValidOpusBrowser) {
              preferredFile = props.src.find(
                (file) =>
                  file &&
                  (file.type === 'audio/ogg' || file.type === 'audio/opus')
              )
            }

            if (!isValidOpusBrowser || !preferredFile) {
              preferredFile = props.src.find(
                (file) => file && supportedFileTypes.includes(file.type)
              )
            }
          }
          return preferredFile
        }),
        peaksData: {},
        position: 0,
        playbackProgress: 0,
        playbackRate: {
          options: [0.5, 0.8, 1.0, 1.5, 2],
          value: 1.0,
          label: computed(() => `${state.playbackRate.value}x`),
          currentIndex: 2,
        },
        startTime: 0,
        showVolumeControl: false,
        showDrawerDescription: false,
        showDrawerPlayer: false,
        showDrawerSubMenu: false,
        title: {},
        volume: {
          min: 0,
          max: 100,
          value: 100,
          mutedAt: 0,
          muted: false,
        },
        waiting: false,
        waveformDataReady: computed(() => {
          if (state.duration > 0) {
            if (
              state.source.peaksUrl ||
              (state.peaksData && state.peaksData.data)
            ) {
              return true
            }
          }
          return false
        }),
        waveformHeight: 80,
        waveformWidth: 250,
        windowSize: useWindowSize(),
      })

      const unlockAudioContext = (audioContext) => {
        // if the audioContext is locked in a suspended state, unlock it when the user gestures on the page
        if (audioContext.state !== 'suspended') return
        const b = document.body
        const events = ['touchstart', 'touchend', 'mousedown', 'keydown']
        const unlock = () => {
          audioContext.resume().then(clean)
        }
        const clean = () => {
          events.forEach((e) => b.removeEventListener(e, unlock))
        }
        events.forEach((e) => b.addEventListener(e, unlock, false))
      }

      // we may need this later but commenting out for auto playback
      // const fetchAudioFile = async () => {
      //   await fetch(props.src[0].url)
      //     .then((response) => {
      //       if (!response.ok) {
      //         throw new Error('HTTP error ' + response.status)
      //       }
      //       console.log('audio fetched')
      //       return response.blob()
      //     })
      //     .then((blob) => {
      //       state.localUrl = URL.createObjectURL(blob)
      //     })
      //     .catch((e) => {
      //       console.error('error', e)
      //     })
      // }

      const createWaveformData = async () => {
        await fetch(state.source.url)
          .then((response) => {
            if (!response.ok) {
              throw new Error('HTTP error ' + response.status)
            }
            console.log('audio fetched')
            return response.arrayBuffer()
          })
          .then((buffer) => {
            const options = {
              audio_context: audioContext,
              array_buffer: buffer,
            }

            return new Promise((resolve, reject) => {
              WaveformData.createFromAudio(options, (err, waveform) => {
                if (err) {
                  reject(err)
                } else {
                  resolve(waveform)
                }
              })
            })
          })
          .then((waveform) => {
            state.peaksData = waveform.toJSON()
            state.status.ready = true
          })
      }

      const initializeAudio = () => {
        try {
          source = audioContext.createMediaElementSource(audio.value)
          const splitter = audioContext.createChannelSplitter(2)
          const merger = audioContext.createChannelMerger(2)
          source.connect(splitter)

          //route output 0 (left) from the splitter to input 0 (left) on the merger. This is a mono connection, carrying the left output signal to the left input of the Merger.
          splitter.connect(merger, 0, 0)
          //route output 0 (left) from the splitter to input 1 (right) on the merger. This is a mono connection as well, carrying the left output signal to the right input of the Merger.
          splitter.connect(merger, 0, 1)

          // connect the merged audio node to the gain and destination
          merger.connect(gain).connect(audioContext.destination)
          state.status.connected = true
          console.log('audio source connected')
        } catch (error) {
          console.error(error)
        }
      }

      const initializePlayerData = async () => {
        if (!state.status.connected) {
          state.status.loading = true
          initializeAudio()
          // drawer player variations do not require waveform data
          if (props.variation !== 'drawer' && !state.waveformDataReady) {
            await createWaveformData()
          }
          state.status.loading = false
        }
      }

      const play = async (position) => {
        await initializePlayerData()
        if (typeof position === 'number') {
          state.status.seek = position
        }
        updateVolume(state.volume.value)
        audio.value.playbackRate = state.playbackRate.value
        audio.value.currentTime = state.status.seek
        audio.value.play()
        state.status.playing = true
        updateSeek()
        emit('play', state.status.seek)
        console.log(`playback started at ${state.status.seek}`)
      }

      const pause = () => {
        if (audio.value) {
          audio.value.pause()
        }
        state.status.playing = false
        state.status.seek = elapsed()
        emit('paused', state.status.seek)
        console.log(`playback paused at ${state.status.seek}`)
        cancelAnimationFrame(rAF)
      }

      const toggle = async () => {
        if (audioContext.state === 'suspended') {
          await audioContext.resume()
        }

        if (!state.status.playing) {
          return await play()
        } else {
          return pause()
        }
      }

      const elapsed = () => {
        return audio.value ? roundToTwo(audio.value.currentTime) : 0
      }

      const updatePosition = () => {
        state.status.seek = state.status.playing ? elapsed() : state.status.seek
        if (Math.floor(state.status.seek) >= Math.floor(state.duration)) {
          state.status.seek = state.duration
        }
        state.playbackProgress = Math.round(
          (state.status.seek / state.duration) * 100
        )
      }

      const seek = (event, seek = null) => {
        let trackTime, trackPosition, x

        if (event && event.target !== undefined) {
          let target = event.target
          // event type fired for drawer players progress bar
          if (event.type === 'input') {
            x = target.value
            trackPosition = x / 100
            if (state.status.playing) {
              cancelAnimationFrame(rAF)
            }
          } else {
            const rect = target.getBoundingClientRect()
            x = event.clientX - rect.left //x position within the element.
            state.hoverXCoord = x
            trackPosition = x / track.value.offsetWidth
          }
          trackTime = roundToTwo(trackPosition * state.duration)
        }

        if (seek !== null) {
          trackTime = seek
        }
        const currentPlayback = elapsed()
        emit('seek', [currentPlayback, trackTime])
        console.log(`seeked from ${currentPlayback} to ${trackTime}`)
        state.status.seek = trackTime
        updatePosition()

        if (state.status.playing) {
          play(trackTime)
        }
      }

      const updateSeek = () => {
        nextTick(() => {
          if (state.status.playing) {
            updatePosition()
          }
          rAF = requestAnimationFrame(updateSeek)
        })
      }

      const updateVolume = (value) => {
        state.volume.value = Number(value)
        var fraction = parseInt(state.volume.value) / parseInt(state.volume.max)
        gain.gain.value = fraction * fraction
      }

      const toggleMute = () => {
        state.volume.muted = !state.volume.muted

        if (state.volume.muted) {
          state.volume.mutedAt =
            state.volume.value > 0 ? state.volume.value : 100
          state.volume.value = 0
        } else {
          state.volume.value = state.volume.mutedAt
        }
        updateVolume(state.volume.value)
      }

      const toggleVolumeControl = () => {
        state.showVolumeControl = !state.showVolumeControl
      }

      const updatePlaybackRate = (rateChange = 1, resetIndex = 2) => {
        state.playbackRate.currentIndex += rateChange

        if (
          state.playbackRate.currentIndex >= 0 &&
          state.playbackRate.currentIndex <=
            state.playbackRate.options.length - 1
        ) {
          state.playbackRate.value =
            state.playbackRate.options[state.playbackRate.currentIndex]
        } else {
          state.playbackRate.currentIndex = resetIndex
          //default to 1x playback speed if clicking main button
          if (state.playbackRate.options[resetIndex]) {
            state.playbackRate.value = state.playbackRate.options[resetIndex]
          }
        }

        if (audio.value) {
          audio.value.playbackRate = state.playbackRate.value
        }
      }

      const skip = (value) => {
        let skipValue = (state.status.seek += value)
        if (skipValue < 0) {
          skipValue = 0
        }
        if (Math.floor(skipValue) >= Math.floor(state.duration)) {
          skipValue = state.duration
        }

        seek(null, skipValue)
      }

      const toggleDescriptionOverflow = () => {
        state.descriptionOverflowOpen = !state.descriptionOverflowOpen
      }

      const setAudioInfo = () => {
        state.date = audioDate?.value
          ? audioDate.value.querySelector('slot').assignedNodes()[0]
          : null
        state.title = audioTitle?.value
          ? audioTitle.value.querySelector('slot').assignedNodes()[0]
          : null

        state.description = audioDescription?.value
          ? audioDescription.value.querySelector('slot').assignedNodes()[0]
          : null

        if (window.innerWidth <= 1024) {
          const mobileDescription = audioDescriptionOverflow.value
            ? audioDescriptionOverflow.value.querySelector('div')
            : null

          state.descriptionHasOverflow =
            mobileDescription &&
            mobileDescription.clientHeight < mobileDescription.scrollHeight
        } else {
          state.descriptionHasOverflow =
            state.description &&
            state.description.clientHeight < state.description.scrollHeight
        }
      }

      const setDefaultX = () => {
        state.hoverXCoord = 0
      }

      const handleMouseMove = (e) => {
        const xCoord = e.clientX - track.value.getBoundingClientRect().left
        state.hoverXCoord = xCoord
        if (state.duration && state.duration > 0) {
          let trackPosition = xCoord / track.value.offsetWidth
          let trackHoverTime = roundToTwo(trackPosition * state.duration)
          if (trackHoverTime > state.duration) {
            trackHoverTime = roundToTwo(state.duration)
          }
          state.hoverPositionTime = durationFormat(trackHoverTime)
        }
      }

      const attachEvents = () => {
        audio.value.addEventListener('loadedmetadata', async () => {
          console.log('metadata loaded')
          state.duration = audio.value ? Math.floor(audio.value.duration) : 0
          if (props.preload === 'auto' || props.preload === 'metadata') {
            state.status.loading = true
            if (props.variation !== 'drawer' && !state.source.peaksUrl) {
              await createWaveformData()
            }
            state.status.loading = false
          }
        })

        audio.value.addEventListener('error', (e) => {
          console.log(e)
          state.status.error = true
        })

        audio.value.addEventListener(props.playbackTrigger, () => {
          console.log(`${props.playbackTrigger}`)
          emit('ready')
          state.waiting = false
        })

        audio.value.addEventListener('waiting', () => {
          console.log('waiting')
          state.waiting = true
        })

        audio.value.addEventListener('ended', () => {
          console.log('playback completed')
          state.status.playing = false
          state.status.seek = 0
          state.playbackProgress = 0
          emit('completed')
        })

        audio.value.addEventListener('pause', () => {
          state.status.playing = false
        })

        audio.value.addEventListener('play', () => {
          state.status.playing = true
        })
      }

      const openDrawerPlayer = (e) => {
        if (state.isMobileView && fullDrawerPlayer.value) {
          e.preventDefault()
          fullDrawerPlayer.value._instance.exposed.open()
          state.showDrawerPlayer = true
        }
      }

      const closeDrawerPlayer = () => {
        if (state.isMobileView && fullDrawerPlayer.value) {
          fullDrawerPlayer.value._instance.exposed.close()
          state.showDrawerPlayer = false
        }
      }

      const openDrawerDescription = () => {
        state.showDrawerDescription = true
        if (state.isMobileView && fullDrawerPlayer.value) {
          // close full view player
          fullDrawerPlayer.value._instance.exposed.close()
        }
      }

      const closeDrawerDescription = () => {
        if (state.isMobileView && fullDrawerPlayer.value) {
          // close full view player
          fullDrawerPlayer.value._instance.exposed.open()
          // wait for the drawer animation to complete before hiding the description modal
          setTimeout(() => {
            state.showDrawerDescription = false
          }, 300)
        } else {
          state.showDrawerDescription = false
        }
      }

      const toggleDrawerSubMenu = () => {
        state.showDrawerSubMenu = !state.showDrawerSubMenu
      }

      const updateRating = (event) => {
        emit('rating', event.detail[0])
      }

      watch(
        () => state.loading,
        (newState) => {
          emit('loading', newState)
        }
      )

      watch(
        () => state.playbackProgress,
        (newProgress) => {
          emit('progress', newProgress)
        }
      )

      expose({
        play,
        pause,
      })

      onMounted(() => {
        unlockAudioContext(audioContext)
        attachEvents()

        // Initially set the audio info to create an overflow if needed
        nextTick(() => {
          setAudioInfo()
        })

        updateSeek()
      })

      onUpdated(() => {
        setAudioInfo()
      })

      return {
        ...toRefs(state),
        audioTrack,
        track,
        progress,
        volumeControl,
        audio,
        audioDate,
        audioTitle,
        audioDescription,
        audioDescriptionOverflow,
        drawer,
        fullDrawerPlayer,
        drawerSubMenu,
        seek,
        toggle,
        toggleDescriptionOverflow,
        skip,
        updateVolume,
        toggleMute,
        toggleVolumeControl,
        updatePlaybackRate,
        setDefaultX,
        handleMouseMove,
        openDrawerPlayer,
        closeDrawerPlayer,
        openDrawerDescription,
        closeDrawerDescription,
        toggleDrawerSubMenu,
        updateRating,
        copy,
        copied,
      }
    },
  })
</script>

<template>
  <div class="audio-player" :data-variation="variation">
    <audio
      ref="audio"
      :src="source.url"
      crossorigin="anonymous"
      :preload="preload"
      style="display: none"
    ></audio>
    <div
      v-if="variation === 'simple'"
      class="simple-main"
      :data-info-populated="infoPopulated"
    >
      <div class="simple-artwork">
        <div class="artwork">
          <sb-image
            :src.prop="artwork.url"
            :alt.prop="artwork.alt"
            :width.prop="artwork.width"
            :height.prop="artwork.height"
          ></sb-image>
        </div>
        <button
          class="[ play-btn btn icon-btn ]"
          :data-loading="status.loading || status.error || waiting"
          :disabled="status.loading || status.error || waiting"
          @click="toggle"
        >
          <div v-show="!status.playing" class="icon">
            <ic-baseline-play-arrow />
          </div>
          <div v-show="status.playing" class="icon">
            <ic-baseline-pause />
          </div>
        </button>
      </div>
      <div
        class="[ simple-info ] [ flow ]"
        :data-info-populated="infoPopulated"
      >
        <div ref="audioTitle" class="title">
          <slot name="title"></slot>
        </div>
        <div ref="audioDescription" class="description">
          <slot name="description"></slot>
        </div>
        <div v-if="descriptionHasOverflow" class="overflow-control">
          <button
            class="[ overflow-btn btn-text ]"
            @click="toggleDescriptionOverflow"
          >
            {{ descriptionOverflowBtnText }}
          </button>
        </div>
      </div>
      <div
        v-show="!status.loading && status.ready"
        class="audio-timeline"
        :data-info-populated="infoPopulated"
      >
        <span class="[ time start ]">{{ currentPlayback }}</span>
        <div ref="track" class="track">
          <sb-waveform
            v-if="waveformDataReady"
            :peaks-data.prop="peaksData"
            :peaks-url.prop="source.peaksUrl"
            :track-duration.prop="duration"
            .track-status="status"
            :waveform-size.prop="{
              height: waveformHeight,
              width: waveformWidth,
              scale: 3,
            }"
            @click="seek"
          ></sb-waveform>
        </div>
        <span class="[ time end ]">{{ fullDuration }}</span>
        <div class="playback-controls">
          <sb-button
            type="button"
            variation="icon-only"
            @click="skip(-1 * backward)"
          >
            <div class="skip-icon">
              <svg
                width="26"
                height="32"
                viewBox="0 0 26 32"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M13 6.4V0L4.875 8L13 16V9.6C18.3787 9.6 22.75 13.904 22.75 19.2C22.75 24.496 18.3787 28.8 13 28.8C7.62125 28.8 3.25 24.496 3.25 19.2H0C0 26.272 5.8175 32 13 32C20.1825 32 26 26.272 26 19.2C26 12.128 20.1825 6.4 13 6.4Z"
                  fill="currentColor"
                />
              </svg>
              <span class="value">{{ backward }}</span>
            </div>
          </sb-button>
          <div class="playback-rate-controls">
            <sb-button
              type="button"
              variation="icon-only"
              @click="updatePlaybackRate(-1)"
            >
              <gridicons-minus-small width="1em" height="1em" />
            </sb-button>
            <div class="playback-rate">
              {{ playbackRate.label }}
            </div>
            <sb-button
              type="button"
              variation="icon-only"
              @click="updatePlaybackRate(1)"
            >
              <gridicons-plus-small width="1em" height="1em" />
            </sb-button>
          </div>
          <sb-button type="button" variation="icon-only" @click="skip(forward)">
            <div class="skip-icon">
              <svg
                width="26"
                height="32"
                viewBox="0 0 26 32"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M13 6.4V0L21.125 8L13 16V9.6C7.62125 9.6 3.25 13.904 3.25 19.2C3.25 24.496 7.62125 28.8 13 28.8C18.3787 28.8 22.75 24.496 22.75 19.2H26C26 26.272 20.1825 32 13 32C5.8175 32 0 26.272 0 19.2C0 12.128 5.8175 6.4 13 6.4Z"
                  fill="currentColor"
                />
              </svg>
              <span class="value">{{ forward }}</span>
            </div>
          </sb-button>
        </div>
      </div>
      <!-- Skeleton Loading State -->
      <div
        v-show="status.loading && !waiting"
        class="audio-timeline"
        :data-info-populated="infoPopulated"
      >
        <span class="[ time start ]"
          ><sb-skeleton-box width="42px" height="24px"></sb-skeleton-box
        ></span>
        <div class="track">
          <sb-skeleton-box height="80px" width="100%"></sb-skeleton-box>
        </div>
        <span class="[ time end ]"
          ><sb-skeleton-box width="42px" height="30px"></sb-skeleton-box
        ></span>
        <div class="playback-controls">
          <sb-skeleton-box width="100%" height="24px"></sb-skeleton-box>
        </div>
      </div>
    </div>
    <div v-if="variation === 'podcast'" class="podcast-main">
      <div class="podcast-artwork">
        <sb-image
          v-if="artwork && artwork.url"
          :src.prop="artwork.url"
          :alt.prop="artwork.alt"
          :width.prop="artwork.width"
          :height.prop="artwork.height"
        ></sb-image>
        <div v-else class="[ placeholder icon ]">
          <mdi-microphone />
        </div>
      </div>
      <div class="podcast-header">
        <div ref="audioTitle" class="[ title ] [ font-bold ]">
          <slot name="title"></slot>
        </div>
      </div>
      <div v-show="!status.loading && status.ready" class="audio-timeline">
        <span class="[ time start ]">{{ currentPlayback }}</span>
        <div ref="track" class="track">
          <sb-waveform
            v-if="waveformDataReady"
            :peaks-data.prop="peaksData"
            :peaks-url.prop="src[0].peaksUrl"
            :track-duration.prop="duration"
            .track-status="status"
            :waveform-size.prop="{
              height: waveformHeight,
              width: waveformWidth,
              scale: 3,
            }"
            @click="seek"
          ></sb-waveform>
        </div>
        <span class="[ time end ]">{{ fullDuration }}</span>
      </div>
      <!-- Skeleton Loading State -->
      <div v-show="status.loading && !waiting" class="audio-timeline">
        <span class="[ time start ]"
          ><sb-skeleton-box width="42px" height="24px"></sb-skeleton-box
        ></span>
        <div class="track">
          <sb-skeleton-box height="80px" width="100%"></sb-skeleton-box>
        </div>
        <span class="[ time end ]"
          ><sb-skeleton-box width="42px" height="24px"></sb-skeleton-box
        ></span>
      </div>
      <div class="podcast-controls">
        <div class="playback-rate-controls">
          <sb-button
            type="button"
            variation="icon-only"
            @click="updatePlaybackRate(-1)"
          >
            <gridicons-minus-small />
          </sb-button>
          <div class="[ playback-rate ]">
            {{ playbackRate.label }}
          </div>
          <sb-button
            type="button"
            variation="icon-only"
            @click="updatePlaybackRate(1)"
          >
            <gridicons-plus-small />
          </sb-button>
        </div>
        <div class="playback-controls">
          <sb-button
            type="button"
            variation="icon-only"
            @click="skip(-1 * backward)"
          >
            <div class="skip-icon">
              <svg
                width="26"
                height="32"
                viewBox="0 0 26 32"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M13 6.4V0L4.875 8L13 16V9.6C18.3787 9.6 22.75 13.904 22.75 19.2C22.75 24.496 18.3787 28.8 13 28.8C7.62125 28.8 3.25 24.496 3.25 19.2H0C0 26.272 5.8175 32 13 32C20.1825 32 26 26.272 26 19.2C26 12.128 20.1825 6.4 13 6.4Z"
                  fill="currentColor"
                />
              </svg>
              <span class="value">{{ backward }}</span>
            </div>
          </sb-button>
          <button
            class="[ play-btn btn icon-btn ]"
            :data-loading="status.loading || status.error || waiting"
            :disabled="status.loading || status.error || waiting"
            @click="toggle"
          >
            <div v-show="!status.playing" class="icon">
              <ic-baseline-play-arrow />
            </div>
            <div v-show="status.playing" class="icon">
              <ic-baseline-pause />
            </div>
          </button>
          <sb-button type="button" variation="icon-only" @click="skip(forward)">
            <div class="skip-icon">
              <svg
                width="26"
                height="32"
                viewBox="0 0 26 32"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M13 6.4V0L21.125 8L13 16V9.6C7.62125 9.6 3.25 13.904 3.25 19.2C3.25 24.496 7.62125 28.8 13 28.8C18.3787 28.8 22.75 24.496 22.75 19.2H26C26 26.272 20.1825 32 13 32C5.8175 32 0 26.272 0 19.2C0 12.128 5.8175 6.4 13 6.4Z"
                  fill="currentColor"
                />
              </svg>
              <span class="value">{{ forward }}</span>
            </div>
          </sb-button>
        </div>
        <div class="volume">
          <sb-button type="button" variation="icon-only" @click="toggleMute">
            <ic-baseline-volume-up v-show="volume.value > 0" />
            <ic-baseline-volume-off v-show="volume.value === 0" />
          </sb-button>
          <input
            :value="volume.value"
            type="range"
            :min="volume.min"
            :max="volume.max"
            :style="`--webkitProgressPercent: ${volume.value}%`"
            @input="updateVolume($event.target.value)"
          />
        </div>
      </div>
    </div>
    <div v-if="variation === 'drawer'" ref="drawer" class="drawer-main">
      <div class="drawer-mini-player">
        <div
          class="progress"
          @blur="setDefaultX"
          @mouseout="setDefaultX"
          @mousemove="handleMouseMove"
        >
          <input
            ref="track"
            :value="playbackProgress"
            :disabled="status.loading"
            type="range"
            min="0"
            max="100"
            :style="`--webkitProgressPercent: ${playbackProgress}%`"
            @input="seek"
          />
          <div
            v-if="duration > 0"
            class="timestamp"
            :style="{ left: `${hoverXCoord}px` }"
          >
            {{ hoverPositionTime }}
          </div>
        </div>
        <div class="drawer-metadata">
          <div class="drawer-artwork">
            <sb-image
              class="drawer-artwork"
              :src.prop="artwork.url"
              :alt.prop="artwork.alt"
              :width.prop="artwork.width"
              :height.prop="artwork.height"
            ></sb-image>
          </div>
          <div class="drawer-info">
            <div ref="audioDate" class="date">
              <slot name="date"></slot>
            </div>
            <div ref="audioTitle" class="title">
              <slot name="title"></slot>
            </div>
            <div class="description">
              <sb-button
                part="descriptionButton"
                variation="text"
                @click="showDrawerDescription = true"
                >View description</sb-button
              >
              <div ref="drawerSubMenu" class="sub-menu">
                <sb-button
                  class="sub-menu-btn"
                  variation="icon-only"
                  @click="toggleDrawerSubMenu"
                >
                  <gg-more-alt />
                </sb-button>
                <div class="menu" :data-open="showDrawerSubMenu">
                  <div class="rating">
                    <sb-rating @change="updateRating" />
                    <div class="rate">Rate this episode</div>
                  </div>
                  <div class="share">
                    <sb-button v-if="!copied" variation="link" @click="copy()">
                      <div class="share-link">
                        <mdi-link />
                        Share episode link
                      </div>
                    </sb-button>
                    <div v-else class="share-link">Episode Link Copied!</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="drawer-controls">
          <div class="playback">
            <sb-button
              type="button"
              variation="icon-only"
              class="seek"
              @click="skip(-1 * backward)"
            >
              <div class="skip-icon">
                <svg
                  width="34"
                  height="42"
                  viewBox="0 0 26 32"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M13 6.4V0L4.875 8L13 16V9.6C18.3787 9.6 22.75 13.904 22.75 19.2C22.75 24.496 18.3787 28.8 13 28.8C7.62125 28.8 3.25 24.496 3.25 19.2H0C0 26.272 5.8175 32 13 32C20.1825 32 26 26.272 26 19.2C26 12.128 20.1825 6.4 13 6.4Z"
                    fill="currentColor"
                  />
                </svg>
                <span class="value">{{ backward }}</span>
              </div>
            </sb-button>
            <button
              class="[ play-btn btn icon-btn ]"
              :data-loading="status.loading || status.error || waiting"
              :disabled="status.loading || status.error || waiting"
              @click="toggle"
            >
              <div v-show="!status.playing" class="icon">
                <ic-baseline-play-arrow />
              </div>
              <div v-show="status.playing" class="icon">
                <ic-baseline-pause />
              </div>
            </button>
            <sb-button
              type="button"
              variation="icon-only"
              class="seek"
              @click="skip(forward)"
            >
              <div class="skip-icon">
                <svg
                  width="34"
                  height="42"
                  viewBox="0 0 26 32"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M13 6.4V0L21.125 8L13 16V9.6C7.62125 9.6 3.25 13.904 3.25 19.2C3.25 24.496 7.62125 28.8 13 28.8C18.3787 28.8 22.75 24.496 22.75 19.2H26C26 26.272 20.1825 32 13 32C5.8175 32 0 26.272 0 19.2C0 12.128 5.8175 6.4 13 6.4Z"
                    fill="currentColor"
                  />
                </svg>
                <span class="value">{{ forward }}</span>
              </div>
            </sb-button>
          </div>
          <div class="additional">
            <div ref="volumeControl" class="volume">
              <sb-button
                type="button"
                variation="icon-only"
                @click="toggleVolumeControl"
              >
                <ic-baseline-volume-up />
              </sb-button>
              <div class="volume-control" :data-open="showVolumeControl">
                <sb-button
                  type="button"
                  variation="icon-only"
                  @click="toggleMute"
                >
                  <ic-baseline-volume-up v-show="volume.value > 0" />
                  <ic-baseline-volume-off v-show="volume.value === 0" />
                </sb-button>
                <input
                  :value="volume.value"
                  type="range"
                  :min="volume.min"
                  :max="volume.max"
                  :style="`--webkitProgressPercent: ${volume.value}%`"
                  @input="updateVolume($event.target.value)"
                />
              </div>
            </div>
            <div class="playback-rate">
              <sb-button variation="text" @click="updatePlaybackRate(1, 0)">
                {{ playbackRate.label }}
              </sb-button>
            </div>
          </div>
        </div>
        <!-- hidden button to turn mini player panel into a control -->
        <button
          v-if="isMobileView"
          class="open-player-btn"
          @click="openDrawerPlayer"
        >
          open player
        </button>
      </div>
      <sb-drawer
        v-if="isMobileView"
        ref="fullDrawerPlayer"
        :offset.prop="drawerPlayerConfig.offset"
      >
        <div slot="header">
          <div class="header">
            <sb-button variation="icon-only" @click="closeDrawerPlayer">
              <carbon-chevron-down />
            </sb-button>
          </div>
        </div>
        <div class="drawer-full-player">
          <div class="drawer-metadata">
            <div class="drawer-artwork">
              <sb-image
                class="drawer-artwork"
                :src.prop="artwork.url"
                :alt.prop="artwork.alt"
                :width.prop="artwork.width"
                :height.prop="artwork.height"
              ></sb-image>
            </div>
            <div class="drawer-info">
              <div class="date">
                <div v-html="date.innerHTML"></div>
              </div>
              <div class="title">
                <div v-html="title.innerHTML"></div>
              </div>
              <div class="rating">
                <sb-rating @change="updateRating" />
              </div>
              <div class="description">
                <sb-button
                  part="descriptionButton"
                  variation="text"
                  @click="openDrawerDescription"
                  >View description</sb-button
                >
              </div>
            </div>
          </div>
          <div
            class="progress"
            @blur="setDefaultX"
            @mouseout="setDefaultX"
            @mousemove="handleMouseMove"
          >
            <input
              ref="track"
              :value="playbackProgress"
              :disabled="status.loading"
              type="range"
              min="0"
              max="100"
              :style="`--webkitProgressPercent: ${playbackProgress}%`"
              @input="seek"
            />
            <div
              v-if="duration > 0"
              class="timestamp"
              :style="{ left: `${hoverXCoord}px` }"
            >
              {{ hoverPositionTime }}
            </div>
          </div>
          <div class="drawer-controls">
            <div class="playback-rate">
              <sb-button variation="text" @click="updatePlaybackRate(1, 0)">
                {{ playbackRate.label }}
              </sb-button>
            </div>
            <div class="playback">
              <sb-button
                type="button"
                variation="icon-only"
                class="seek"
                @click="skip(-1 * backward)"
              >
                <div class="skip-icon">
                  <svg
                    width="26"
                    height="32"
                    viewBox="0 0 26 32"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M13 6.4V0L4.875 8L13 16V9.6C18.3787 9.6 22.75 13.904 22.75 19.2C22.75 24.496 18.3787 28.8 13 28.8C7.62125 28.8 3.25 24.496 3.25 19.2H0C0 26.272 5.8175 32 13 32C20.1825 32 26 26.272 26 19.2C26 12.128 20.1825 6.4 13 6.4Z"
                      fill="currentColor"
                    />
                  </svg>
                  <span class="value">{{ backward }}</span>
                </div>
              </sb-button>
              <button
                class="[ play-btn btn icon-btn ]"
                :data-loading="status.loading || status.error || waiting"
                :disabled="status.loading || status.error || waiting"
                @click="toggle"
              >
                <div v-show="!status.playing" class="icon">
                  <ic-baseline-play-arrow />
                </div>
                <div v-show="status.playing" class="icon">
                  <ic-baseline-pause />
                </div>
              </button>
              <sb-button
                type="button"
                variation="icon-only"
                class="seek"
                @click="skip(forward)"
              >
                <div class="skip-icon">
                  <svg
                    width="26"
                    height="32"
                    viewBox="0 0 26 32"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M13 6.4V0L21.125 8L13 16V9.6C7.62125 9.6 3.25 13.904 3.25 19.2C3.25 24.496 7.62125 28.8 13 28.8C18.3787 28.8 22.75 24.496 22.75 19.2H26C26 26.272 20.1825 32 13 32C5.8175 32 0 26.272 0 19.2C0 12.128 5.8175 6.4 13 6.4Z"
                      fill="currentColor"
                    />
                  </svg>
                  <span class="value">{{ forward }}</span>
                </div>
              </sb-button>
            </div>
            <div class="share">
              <sb-button v-if="!copied" variation="icon-only" @click="copy()">
                <ei-share-apple
              /></sb-button>
              <div v-else>
                <clarity-success-standard-line />
              </div>
            </div>
          </div>
        </div>
      </sb-drawer>
      <sb-modal
        :active.prop="showDrawerDescription"
        :style="{
          '--modal-content-top-position': isMobileView
            ? `${drawerPlayerConfig.offset}px`
            : `calc(30% + ${drawerPlayerConfig.offset}px)`,
        }"
        @close="closeDrawerDescription"
      >
        <div class="drawer-description">
          <div class="title">Episode Description</div>
          <div ref="audioDescription">
            <slot name="description"></slot>
          </div>
        </div>
      </sb-modal>
    </div>
    <div
      v-if="variation !== 'drawer'"
      ref="audioDescriptionOverflow"
      class="simple-info-overflow"
      :data-open="descriptionOverflowOpen"
      :data-has-overflow="descriptionHasOverflow"
    >
      <sb-button
        class="close-btn"
        variation="icon-only"
        @click="toggleDescriptionOverflow"
        ><mdi-close
      /></sb-button>
      <div v-if="description" class="description">
        <div v-html="description.innerHTML"></div>
      </div>
      <div
        v-if="descriptionHasOverflow || descriptionOverflowOpen"
        class="overflow-control"
      >
        <button
          class="[ overflow-btn btn-text ]"
          @click="toggleDescriptionOverflow"
        >
          {{ descriptionOverflowBtnText }}
        </button>
      </div>
    </div>
  </div>
</template>

<style>
  @import '@storyboard-fm/storyboard-css/blocks/audio-player/index.css';
  @import '@storyboard-fm/storyboard-css/blocks/button.css';
  @import '@storyboard-fm/storyboard-css/utilities/font.css';
  @import '@storyboard-fm/storyboard-css/utilities/visually-hidden.css';

  :host {
    --ap-background: #faf4e3;
    --ap-box-shadow: none;
    --ap-btn-color: #000000;
    --ap-btn-active-color: #000000;
    --ap-btn-outline: #8c98a4;
    --ap-icon-color: #575656;
    --ap-icon-active-color: #000000;
    --ap-artwork-border-color: #575656;
    --ap-progress-color: #ba61ff;
    --ap-progress-slider-color: #ba61ff;
    --ap-progress-background-color: #c4c4c4;
    --ap-volume-control-background-color: #fffcf2;
    --ap-volume-color: #ba61ff;
    --ap-volume-slider-color: #ba61ff;
    --ap-volume-background-color: #c4c4c4;
    --ap-loading-color: #ba61ff;
    --ap-info-overflow-background: #fffcf2;
    --ap-drawer-modal-border-radius: 0.5rem;
    --ap-drawer-modal-background: #fffcf2;
    --ap-drawer-player-height: 100%;
    --ap-timestamp-background-color: #fffcf2;
    --ap-timestamp-text-color: #000000;
    --ap-timestamp-border: 1px solid #c4c4c4;
    --ap-timestamp-border-radius: 4px;
    --ap-link-text: #692ea4;
  }
</style>
