<script>
  import {
    defineComponent,
    reactive,
    toRefs,
    inject,
    onMounted,
    computed,
  } from 'vue'

  export default defineComponent({
    name: 'TabPane',
    props: {},
    setup: () => {
      const tabs = inject('tabs')
      console.log(tabs)
      const state = reactive({
        isActive: computed(() => state.index === tabs.selectedIndex),
        index: 0,
      })

      onMounted(() => {
        console.log(tabs)
        state.index = tabs.count
        tabs.count++
      })

      return {
        ...toRefs(state),
      }
    },
  })
</script>

<template>
  <div class="tab" :data-active="isActive">
    <div class="title">
      <slot name="title"></slot>
    </div>
    <slot></slot>
  </div>
</template>

<style>
  .tab {
    width: 0;
    height: 0;
    opacity: 0;
  }

  .tab[data-active='true'] {
    display: flex;
    width: 100%;
    min-height: 130px;
    align-items: flex-start;
    opacity: 1;
  }

  .tab .title {
    display: none;
  }
</style>
