<script>
  import { defineComponent, computed, toRefs, reactive } from 'vue'
  import zxcvbn from 'zxcvbn'

  export default defineComponent({
    name: 'PasswordStrength',
    props: {
      password: {
        type: String,
        default: '',
      },
      messaging: {
        type: Object,
        default: () => {
          return {
            recommendation:
              'Combine uppercase and lowercase letters. Add special characters. Do not use names or dictionary words.',
            four: { strength: 'Strong password!', suggestion: '' },
            three: {
              strength: 'Good.',
              suggestion:
                'Better! For a strong password, make your password longer.',
            },
            two: {
              strength: 'Moderate.',
              suggestion:
                'Better! For a strong password, make your password longer.',
            },
            one: {
              strength: 'Weak.',
              suggestion: 'Make your password longer than 8 characters.',
            },
          }
        },
      },
    },
    setup: (props) => {
      const state = reactive({
        passwordStrength: computed(() => zxcvbn(props.password).score),
        strength: computed(() => {
          let strength = ''
          switch (state.passwordStrength) {
            case 4:
              strength = props.messaging.four.strength
              break
            case 3:
              strength = props.messaging.three.strength
              break
            case 2:
              strength = props.messaging.two.strength
              break
            case 1:
              strength = props.messaging.one.strength
              break
            default:
              strength = ''
              break
          }

          return strength
        }),
        message: computed(() => {
          let suggestion = ''
          let message = ''

          if (props.password) {
            message = props.messaging.recommendation
            switch (state.passwordStrength) {
              case 4:
                suggestion = props.messaging.four.suggestion
                message = ''
                break
              case 3:
                suggestion = props.messaging.three.suggestion
                break
              case 2:
                suggestion = props.messaging.two.suggestion
                break
              case 1:
                suggestion = props.messaging.one.suggestion
                break
              default:
                message = ''
                suggestion = ''
                break
            }
          }

          return `${suggestion ? suggestion + ' ' : ''}${message}`
        }),
      })

      return {
        ...toRefs(state),
      }
    },
  })
</script>

<template>
  <div class="password-strength">
    <span
      class="password-strength-level"
      :class="{
        'level-1': password && passwordStrength <= 1,
        'level-2': passwordStrength == 2,
        'level-3': passwordStrength == 3,
        'level-4': passwordStrength == 4,
      }"
    ></span>
    <span
      class="password-strength-level"
      :class="{
        'level-0': passwordStrength <= 1,
        'level-2': passwordStrength == 2,
        'level-3': passwordStrength == 3,
        'level-4': passwordStrength == 4,
      }"
    ></span>
    <span
      class="password-strength-level"
      :class="{
        'level-0': passwordStrength <= 2,
        'level-3': passwordStrength == 3,
        'level-4': passwordStrength == 4,
      }"
    ></span>
    <span
      class="password-strength-level"
      :class="{
        'level-0': passwordStrength <= 3,
        'level-4': passwordStrength == 4,
      }"
    ></span>
  </div>
  <div class="strength-message" :data-level="passwordStrength">
    {{ strength }}
  </div>
  <div class="message">
    {{ message }}
  </div>
</template>

<style>
  :host {
    --ps-level-0-color: #c4c4c4;
    --ps-level-1-color: #d9000c;
    --ps-level-2-color: #ff581c;
    --ps-level-3-color: #ff581c;
    --ps-level-4-color: #229967;
  }

  .password-strength {
    display: flex;
    width: 100%;
    align-items: center;
    padding: 0.5rem 0;
  }

  .password-strength-level {
    width: 3.25rem;
    height: 0.325rem;
    margin-right: 5px;
    background-color: var(--ps-level-0-color);
  }

  .password-strength-level.level-1 {
    background-color: var(--ps-level-1-color);
  }

  .password-strength-level.level-2 {
    background-color: var(--ps-level-2-color);
  }

  .password-strength-level.level-3 {
    background-color: var(--ps-level-3-color);
  }

  .password-strength-level.level-4 {
    background-color: var(--ps-level-4-color);
  }

  .strength-message {
    color: var(--ps-level-1-color);
    font-weight: 500;
  }

  .strength-message[data-level='2'] {
    color: var(--ps-level-3-color);
  }

  .strength-message[data-level='3'] {
    color: var(--ps-level-3-color);
  }

  .strength-message[data-level='4'] {
    color: var(--ps-level-4-color);
  }

  .message {
    padding-top: 0.325rem;
  }
</style>
