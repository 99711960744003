import { defineCustomElement } from 'vue'
import AccordionWithStyles from './components/Accordion.ce.vue'
import AudioPlayerWithStyles from './components/AudioPlayer.ce.vue'
import AvatarWithStyles from './components/Avatar.ce.vue'
import BannerWithStyles from './components/Banner.ce.vue'
import ButtonWithStyles from './components/Button.ce.vue'
import CardWithStyles from './components/Card.ce.vue'
import CheckboxWithStyles from './components/Checkbox.ce.vue'
import DataTableWithStyles from './components/DataTable.ce.vue'
import DragDropWithStyles from './components/DragDrop.ce.vue'
import DrawerWithStyles from './components/Drawer.ce.vue'
import ExpandableMenuWithStyles from './components/ExpandableMenu.ce.vue'
import FormWithStyles from './components/Form.ce.vue'
import ImageWithStyles from './components/Image.ce.vue'
import InputWithStyles from './components/Input.ce.vue'
import LinkWithStyles from './components/Link.ce.vue'
import ModalWithStyles from './components/Modal.ce.vue'
import PaginationWithStyles from './components/Pagination.ce.vue'
import PasswordStrengthWithStyles from './components/PasswordStrength.ce.vue'
import RatingWithStyles from './components/Rating.ce.vue'
import SelectWithStyles from './components/Select.ce.vue'
import SkeletonBoxWithStyles from './components/SkeletonBox.ce.vue'
import TabPaneWithStyles from './components/Tabs/TabPane.ce.vue'
import TabsWithStyles from './components/Tabs/Tabs.ce.vue'
import ToastNotificationWithStyles from './components/ToastNotification.ce.vue'
import UploadWithStyles from './components/Upload.ce.vue'
import UploadAvatarWithStyles from './components/UploadAvatar.ce.vue'
import WaveformWithStyles from './components/Waveform.ce.vue'

// optional export for registering our input validation rules
export { registerInputValidationRules } from './shared/validation-rules'

// exported utilities and composable features
export { useIndexedDB } from './features/indexedDB'
export { useFocusTrap } from './features/focusTrap'
export { useDevice } from './features/deviceDetector'
export { useScrollLock } from './features/scrollLock'

// custom element exports to register as needed
export const Accordion = defineCustomElement(AccordionWithStyles)
export const AudioPlayer = defineCustomElement(AudioPlayerWithStyles)
export const Avatar = defineCustomElement(AvatarWithStyles)
export const Banner = defineCustomElement(BannerWithStyles)
export const Button = defineCustomElement(ButtonWithStyles)
export const Card = defineCustomElement(CardWithStyles)
export const Checkbox = defineCustomElement(CheckboxWithStyles)
export const DataTable = defineCustomElement(DataTableWithStyles)
export const DragDrop = defineCustomElement(DragDropWithStyles)
export const Drawer = defineCustomElement(DrawerWithStyles)
export const ExpandableMenu = defineCustomElement(ExpandableMenuWithStyles)
export const Form = defineCustomElement(FormWithStyles)
export const Image = defineCustomElement(ImageWithStyles)
export const Input = defineCustomElement(InputWithStyles)
export const Link = defineCustomElement(LinkWithStyles)
export const Modal = defineCustomElement(ModalWithStyles)
export const Pagination = defineCustomElement(PaginationWithStyles)
export const PasswordStrength = defineCustomElement(PasswordStrengthWithStyles)
export const Rating = defineCustomElement(RatingWithStyles)
export const Select = defineCustomElement(SelectWithStyles)
export const SkeletonBox = defineCustomElement(SkeletonBoxWithStyles)
export const Tabs = defineCustomElement(TabsWithStyles)
export const TabPane = defineCustomElement(TabPaneWithStyles)
export const ToastNotification = defineCustomElement(
  ToastNotificationWithStyles
)
export const Upload = defineCustomElement(UploadWithStyles)
export const UploadAvatar = defineCustomElement(UploadAvatarWithStyles)
export const Waveform = defineCustomElement(WaveformWithStyles)

// function to register all custom elements at once
export function register() {
  if (!customElements.get('sb-accordion')) {
    customElements.define('sb-accordion', Accordion)
  }
  if (!customElements.get('sb-audio-player')) {
    customElements.define('sb-audio-player', AudioPlayer)
  }
  if (!customElements.get('sb-avatar')) {
    customElements.define('sb-avatar', Avatar)
  }
  if (!customElements.get('sb-banner')) {
    customElements.define('sb-banner', Banner)
  }
  if (!customElements.get('sb-button')) {
    customElements.define('sb-button', Button)
  }
  if (!customElements.get('sb-card')) {
    customElements.define('sb-card', Card)
  }
  if (!customElements.get('sb-checkbox')) {
    customElements.define('sb-checkbox', Checkbox)
  }
  if (!customElements.get('sb-data-table')) {
    customElements.define('sb-data-table', DataTable)
  }
  if (!customElements.get('sb-drag-drop')) {
    customElements.define('sb-drag-drop', DragDrop)
  }
  if (!customElements.get('sb-drawer')) {
    customElements.define('sb-drawer', Drawer)
  }
  if (!customElements.get('sb-expandable-menu')) {
    customElements.define('sb-expandable-menu', ExpandableMenu)
  }
  if (!customElements.get('sb-form')) {
    customElements.define('sb-form', Form)
  }
  if (!customElements.get('sb-image')) {
    customElements.define('sb-image', Image)
  }
  if (!customElements.get('sb-input')) {
    customElements.define('sb-input', Input)
  }
  if (!customElements.get('sb-link')) {
    customElements.define('sb-link', Link)
  }
  if (!customElements.get('sb-modal')) {
    customElements.define('sb-modal', Modal)
  }
  if (!customElements.get('sb-pagination')) {
    customElements.define('sb-pagination', Pagination)
  }
  if (!customElements.get('sb-password-strength')) {
    customElements.define('sb-password-strength', PasswordStrength)
  }
  if (!customElements.get('sb-rating')) {
    customElements.define('sb-rating', Rating)
  }
  if (!customElements.get('sb-select')) {
    customElements.define('sb-select', Select)
  }
  if (!customElements.get('sb-skeleton-box')) {
    customElements.define('sb-skeleton-box', SkeletonBox)
  }
  if (!customElements.get('sb-tabs')) {
    customElements.define('sb-tabs', Tabs)
  }
  if (!customElements.get('sb-tab-pane')) {
    customElements.define('sb-tab-pane', TabPane)
  }
  if (!customElements.get('sb-toast-notification')) {
    customElements.define('sb-toast-notification', ToastNotification)
  }
  if (!customElements.get('sb-upload')) {
    customElements.define('sb-upload', Upload)
  }
  if (!customElements.get('sb-upload-avatar')) {
    customElements.define('sb-upload-avatar', UploadAvatar)
  }
  if (!customElements.get('sb-waveform')) {
    customElements.define('sb-waveform', Waveform)
  }
}
