<script>
  import { defineComponent } from 'vue'
  export default defineComponent({
    name: 'Accordion',
    props: {
      index: { type: Number, default: null, required: true },
    },
    setup: () => {},
  })
</script>

<template>
  <div class="accordion">
    <input
      :id="`data-accordion-${index}`"
      class="accordion-input"
      type="checkbox"
    />
    <label class="accordion-input-label" :for="`data-accordion-${index}`">
      <div class="title"><slot name="title"></slot></div>
      <div class="icon">
        <mdi-chevron-down />
      </div>
    </label>
    <div class="accordion-content">
      <slot name="content"></slot>
    </div>
  </div>
</template>

<style>
  /* import base accordion styles from css library */
  @import '@storyboard-fm/storyboard-css/blocks/accordion.css';

  :host {
    --accordion-icon-color: #575656;
    --accordion-border-color: #c4c4c4;
  }
</style>
