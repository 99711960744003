<script>
  import { defineComponent, computed } from 'vue'

  export default defineComponent({
    name: 'CustomLink',
    inheritAttrs: false,
    props: {
      url: { type: String, required: true },
      target: { type: String, default: null },
      rel: { type: String, default: null },
      asButton: { type: Boolean, default: false },
      download: { type: String, default: null },
      buttonVariation: {
        type: String,
        default: 'rounded',
        validator(value) {
          return ['rounded', 'inverse', 'icon', 'icon-only'].includes(value)
        },
      },
    },
    setup: (props) => {
      const linkClasses = computed(() => {
        let classes = 'link'
        if (props.asButton) {
          switch (props.buttonVariation) {
            case 'rounded':
              classes = '[ link link-btn ]'
              break
            case 'inverse':
              classes = '[ inverse-link inverse-link-btn ]'
              break
            case 'icon':
              classes = '[ icon-btn ]'
              break
            case 'icon-only':
              classes = '[ icon-btn icon-only ]'
              break
            default:
              classes = '[ link link-btn ]'
              break
          }
        }
        return classes
      })

      return {
        linkClasses,
      }
    },
  })
</script>

<template>
  <a
    :class="linkClasses"
    :href="url"
    :target="target"
    :rel="rel"
    :download="download"
  >
    <!-- default slot, i.e. link text, this can be a string or an html block to render with an icon -->
    <slot></slot>
  </a>
</template>

<style>
  /* import base link styles from css library */
  @import '@storyboard-fm/storyboard-css/blocks/link.css';
  @import '@storyboard-fm/storyboard-css/blocks/button.css';

  :host {
    --link-text: #692ea4;
    --link-font-weight: 400;
    --link-btn-background: #000000;
    --link-btn-background-active: transparent;
    --link-btn-text: #ffffff;
    --btn-icon-color: #575656;
    --btn-icon-active-color: #000000;
    --link-btn-outline: #8c98a4;
    --inverse-link-btn-background: transparent;
    --inverse-link-btn-text: #000000;
    --inverse-link-btn-outline: #8c98a4;
  }
</style>
