<script>
  import {
    defineComponent,
    onMounted,
    ref,
    reactive,
    toRefs,
    computed,
    onUpdated,
  } from 'vue'
  import { useIndexedDB } from '../features/indexedDB'

  export default defineComponent({
    name: 'Banner',
    props: {
      persist: {
        type: Boolean,
        default: false,
      },
      name: {
        type: String,
        default: '',
        required: true,
      },
    },
    setup: (props) => {
      const content = ref(null)
      const redirect = ref(null)
      let db = props.persist ? useIndexedDB() : null

      const state = reactive({
        // set close to true by default to prevent flashing update when it hides on mounted
        close: true,
        updateDecision: computed(() => props.persist && db !== null),
      })

      const handleClose = async () => {
        state.close = true
        if (state.updateDecision) {
          await db.set('closeBanner', true)
        }
      }

      onMounted(async () => {
        if (state.updateDecision) {
          db.dbName.value = props.name

          let previousDecision = await db.get('closeBanner')
          if (previousDecision == null) {
            await db.set('closeBanner', false)
            state.close = false
          } else {
            state.close = previousDecision
          }
        } else {
          state.close = false
        }
      })

      onUpdated(() => {
        // append the redirect content to be inline with the full content on tablet -> desktop sizes
        let contentText = content.value
          ? content.value.querySelector('slot').assignedNodes()[0]
          : null
        let redirectLink = redirect.value
          ? redirect.value.querySelector('slot').assignedNodes()[0]
          : null

        if (contentText && redirectLink) {
          contentText.innerHTML += `${redirectLink.innerHTML}`
        }
      })

      return {
        ...toRefs(state),
        content,
        redirect,
        handleClose,
      }
    },
  })
</script>

<template>
  <div v-if="!close" class="banner">
    <div class="left-image">
      <slot name="left-image"></slot>
    </div>
    <div class="banner-content">
      <div class="key-text">
        <slot name="key-text"></slot>
      </div>
      <div ref="content" class="full-content">
        <slot name="full-content"></slot>
      </div>
      <div ref="redirect" class="redirect">
        <slot name="redirect"></slot>
      </div>
    </div>
    <div class="right-image">
      <slot name="right-image"></slot>
    </div>
    <sb-button class="close-btn" variation="icon-only" @click="handleClose">
      <mdi-close />
    </sb-button>
  </div>
</template>

<style>
  /* import base modal styles from css library */

  :host {
    --banner-background: #ffeef0;
    --banner-position: relative;
    --banner-font-weight: 500;
  }

  .banner {
    position: var(--banner-position);
    z-index: 30;
    top: 0;
    right: 0;
    left: 0;
    display: flex;
    min-height: 70px;
    box-sizing: border-box;
    align-items: center;
    justify-content: center;
    padding: 1rem 3rem;
    margin: 0 auto;
    background: var(--banner-background);
    text-align: center;
  }

  .banner-content {
    padding: 0 1rem;
    font-weight: var(--banner-font-weight);
  }

  .key-text {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .full-content {
    display: none;
  }

  sb-button.close-btn {
    position: absolute;
    top: 1rem;
    right: 1rem;
  }

  sb-button.close-btn svg {
    width: 0.875em;
    height: 0.875em;
  }

  @media all and (min-width: 800px) {
    sb-button.close-btn {
      top: 50%;
      right: 1.25rem;
      transform: translateY(-50%);
    }

    .key-text,
    .redirect {
      display: none;
    }

    .full-content {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
</style>
