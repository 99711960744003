<script>
  import { defineComponent, ref, reactive, toRefs } from 'vue'

  export default defineComponent({
    name: 'DragDrop',
    props: {},
    emits: ['dropped'],
    setup: (props, { emit }) => {
      const dragDrop = ref(null)
      let inActiveTimeout = null

      const state = reactive({
        active: false,
      })

      const setActive = () => {
        state.active = true
        clearTimeout(inActiveTimeout)
      }

      const setInactive = () => {
        inActiveTimeout = setTimeout(() => {
          state.active = false
        }, 50)
      }

      const handleDrop = (event) => {
        setInactive()
        emit('dropped', event.dataTransfer)
      }

      return {
        ...toRefs(state),
        dragDrop,
        setActive,
        setInactive,
        handleDrop,
      }
    },
  })
</script>

<template>
  <div
    ref="dragDrop"
    class="drag-drop"
    :data-active="active"
    @dragenter.prevent="setActive"
    @dragover.prevent="setActive"
    @dragleave.prevent="setInactive"
    @drop.prevent="handleDrop"
  >
    <slot></slot>
  </div>
</template>

<style>
  :host {
    --drag-drop-active-background: #dddbd5;
  }

  .drag-drop[data-active='true'] {
    background: var(--drag-drop-active-background);
  }
</style>
