<script>
  import { useDrag } from '@vueuse/gesture'
  import { useMotionProperties, useMotionTransitions } from '@vueuse/motion'
  import {
    nextTick,
    computed,
    onBeforeUnmount,
    onMounted,
    ref,
    toRefs,
    defineComponent,
    reactive,
  } from 'vue'
  import { useWindowSize } from '@vueuse/core'
  import { useScrollLock } from '../features/scrollLock'

  export default defineComponent({
    name: 'Drawer',
    props: {
      duration: {
        type: Number,
        default: 300,
      },
      offset: {
        type: Number,
        default: 0,
      },
    },
    emits: ['open', 'close'],
    setup: (props, { emit, expose }) => {
      const drawer = ref(null)
      const drawerHeader = ref(null)
      const drawerContent = ref(null)

      const { motionProperties } = useMotionProperties(drawer)
      const { push } = useMotionTransitions(motionProperties)

      const keyFrame = {
        type: 'keyframe',
        ease: 'linear',
        duration: 0,
        delay: 0,
      }

      const state = reactive({
        axisY: 0,
        contentHeight: computed(() => {
          let height =
            state.windowSize.height -
            (state.headerHeight + Number(props.offset))

          return { 'max-height': `${height}px` }
        }),
        drawerHeight: 0,
        headerHeight: 0,
        isDragging: false,
        open: false,
        scrollLock: useScrollLock(
          document.querySelector('html'),
          'no-scroll',
          drawer.value
        ),
        windowSize: useWindowSize(),
      })

      const dragHandler = (ctx) => {
        state.isDragging = false

        const { dragging } = ctx
        if (dragging) {
          handleDrag(ctx)
        } else {
          handleDragEnd(ctx)
        }
      }

      const calculateHeight = () => {
        nextTick(() => {
          setTimeout(() => {
            state.drawerHeight = drawer.value?.clientHeight
            state.headerHeight = drawerHeader.value?.clientHeight

            // set drawer to hidden
            state.axisY = state.windowSize.height
            push('y', state.axisY, motionProperties, keyFrame)
          }, 100)
        })
      }

      const elHasScrollY = (el) => {
        const { scrollHeight, offsetHeight } = el
        return scrollHeight > offsetHeight
      }

      const elScrolledBottom = (el) => {
        const { scrollHeight, scrollTop, offsetHeight } = el
        return scrollHeight - scrollTop === offsetHeight
      }

      const elScrolledTop = (el) => {
        const { scrollTop } = el
        return scrollTop === 0
      }

      const handleDrag = (ctx) => {
        if (ctx.tap || !allowDrag(ctx)) return
        state.isDragging = true

        const {
          movement: [, y],
        } = ctx

        const setY = state.axisY + y

        // prevent drag over height
        if (y < 0) return
        push('y', setY, motionProperties, keyFrame)
      }

      const handleDragEnd = (ctx) => {
        if (ctx.tap || !allowDrag(ctx)) return
        state.isDragging = false

        const {
          swipe: [, sy],
          movement: [, y],
        } = ctx

        // swipe down
        if (sy > 0) {
          close()
          return
        }
        //   swipe up
        else if (sy < 0) {
          open()
          return
        }

        state.axisY = state.axisY + y

        // Handle Drag
        // drag stop position > 1/2 of the sheet => set to Open

        if (
          state.windowSize.height - state.axisY >
          state.windowSize.height / 3
        ) {
          open()
          return
        } else {
          close()
          return
        }
      }

      const allowDrag = (ctx) => {
        const {
          swipe: [, sy],
          movement: [, y],
        } = ctx

        const dragTarget = ctx?.event?.srcElement
        const isDragUp = y < 0 || sy < 0
        const targetIsContent = dragTarget.closest('#drawer-content')
        // if drag target is scrollable content
        if (elHasScrollY(drawerContent.value) && targetIsContent) {
          // if swipe/drag up and content is scrolled to the bottom, allow drag
          if (isDragUp && elScrolledBottom(drawerContent.value)) return true
          // if swipe/drag up and content is scrolled to the bottom, allow drag
          else if (!isDragUp && elScrolledTop(drawerContent.value)) return true
          // has scroll but not scrolled to top or bottom, don't allow drag
          else return false
        }
        return true
      }

      const open = async () => {
        // show the actual drawer (it is destroyed when the drawer is closed)
        state.isDragging = false

        // make sure everything's loaded
        await nextTick()

        // await 10ms for drawer to appear before accessing DOM
        setTimeout(() => {
          // lock the body scroll
          state.scrollLock.lockScroll()

          // trigger open sheet animation
          state.drawerHeight = drawer.value.clientHeight
          state.axisY = state.windowSize.height - state.drawerHeight
          push('y', state.axisY, motionProperties, {
            ...keyFrame,
            duration: props.duration,
          })

          state.open = true
          emit('open')
        }, 10)
      }

      const close = async () => {
        // unlock the body scroll
        await nextTick()
        state.scrollLock.unlockScroll()

        // trigger close sheet animation
        state.axisY = state.windowSize.height
        push('y', state.axisY, motionProperties, {
          ...keyFrame,
          duration: props.duration,
        })

        // delay 300ms to prevent fixed wrapper hiding before animation ends
        setTimeout(() => {
          state.open = false
          emit('close')
        }, props.duration)
      }

      useDrag(dragHandler, {
        domTarget: drawer,
        filterTaps: true,
        useTouch: true,
      })

      onMounted(() => {
        calculateHeight()
      })

      onBeforeUnmount(async () => {
        await nextTick()
        state.scrollLock.clearLockScroll()
      })

      expose({ open, close })

      return {
        ...toRefs(state),
        drawer,
        drawerHeader,
        drawerContent,
        close,
      }
    },
  })
</script>

<template>
  <div class="drawer-wrapper" :data-open="open">
    <transition name="fade">
      <div v-if="open" class="background" @click.self="close"></div>
    </transition>
    <div
      id="drawer-card"
      ref="drawer"
      class="drawer"
      :data-dragging="isDragging"
    >
      <div ref="drawerHeader" class="drawer-header">
        <slot name="header">
          <div class="icon"></div>
        </slot>
      </div>
      <div
        id="drawer-content"
        ref="drawerContent"
        class="drawer-content"
        :style="contentHeight"
      >
        <slot></slot>
      </div>
    </div>
  </div>
</template>

<style>
  @import '@storyboard-fm/storyboard-css/blocks/drawer.css';
  @import '@storyboard-fm/storyboard-css/utilities/scroll-lock.css';

  :host {
    --drawer-background: #fffcf2;
    --drawer-overlay-color: rgba(196, 196, 196, 0.4);
    --drawer-overlay-filter: none;
    --drawer-max-height: 95%;
    --drawer-content-filter: drop-shadow(0px 3px 10px rgba(0, 0, 0, 0.15));
    --drawer-header-padding: 1rem;
    --drawer-header-icon-color: rgba(0, 0, 0, 0.2);
    --drawer-border: none;
    --drawer-top-border-radius: 35px;
  }
</style>
