<script>
  import { defineComponent, reactive, toRefs, ref, onMounted } from 'vue'

  export default defineComponent({
    name: 'Rating',
    props: {
      maxRating: {
        type: Number,
        default: 5,
      },
      existingRating: {
        type: Number,
        default: 0,
      },
    },
    emits: ['change'],
    setup: (props, { emit }) => {
      const ratingList = ref(null)
      const state = reactive({
        rating: props.existingRating,
      })

      const changeRating = (newRating) => {
        state.rating = newRating
        updateSelection(newRating)
        emit('change', newRating)
      }

      const updateSelection = (rating) => {
        for (let index = 0; index < rating; index++) {
          ratingList.value.children[index].dataset.selected = true
        }
        for (let index = rating; index < props.maxRating; index++) {
          ratingList.value.children[index].dataset.selected = false
        }
      }

      const handleMouseClick = (e) => {
        let element = e.target ?? e
        activate(element)
        let { rating } = element.dataset
        if (e.key !== 'Tab' && rating === state.rating) {
          rating = 0
          resetTabIndex()
          ratingList.value.firstElementChild.tabIndex = 0
        }
        changeRating(rating)
        updateSelection(rating)
      }

      const handleMouseOver = (e) => {
        const { rating } = e.target.dataset
        updateSelection(rating)
      }

      const handleMouseLeave = () => {
        updateSelection(state.rating)
      }

      const focusPrev = () => {
        let focusedElement = document.activeElement.shadowRoot.activeElement
        if (focusedElement.previousElementSibling) {
          handleMouseClick(focusedElement.previousElementSibling)
        }
      }

      const focusNext = () => {
        let focusedElement = document.activeElement.shadowRoot.activeElement
        if (focusedElement.nextElementSibling) {
          handleMouseClick(focusedElement.nextElementSibling)
        }
      }

      const activate = (element) => {
        resetTabIndex()
        element.tabIndex = 0
        element.focus()
      }

      const resetTabIndex = () => {
        ratingList.value.childNodes.forEach(
          (element) => (element.tabIndex = -1)
        )
      }

      onMounted(() => {
        if (props.existingRating > 0) {
          updateSelection(props.existingRating)
        }
      })

      return {
        ...toRefs(state),
        ratingList,
        changeRating,
        updateSelection,
        handleMouseClick,
        handleMouseOver,
        handleMouseLeave,
        focusPrev,
        focusNext,
      }
    },
  })
</script>

<template>
  <ul ref="ratingList" class="rating" @keyup.tab="handleMouseClick">
    <li
      v-for="i in maxRating"
      :key="i"
      class="star"
      :tabindex="i === 1 ? 0 : null"
      :data-rating="i"
      data-selected="false"
      role="button"
      :aria-label="`${i} star rating`"
      @click="handleMouseClick"
      @keyup.left="focusPrev"
      @keyup.right="focusNext"
      @blur="handleMouseLeave"
      @mouseover="handleMouseOver"
      @mouseleave="handleMouseLeave"
    ></li>
  </ul>
</template>

<style>
  @import '@storyboard-fm/storyboard-css/blocks/rating.css';

  :host {
    --rating-height: 2rem;
    --rating-width: 2rem;
    --rating-background-color: #fffcf2;
    --rating-outline-color: #c4c4c4;
    --rating-selected-color: #ffc400;
  }
</style>
