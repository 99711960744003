<script>
  import { defineComponent, reactive, toRefs, computed } from 'vue'

  export default defineComponent({
    name: 'Avatar',
    props: {
      src: {
        type: String,
        default: '',
      },
      username: {
        type: String,
        required: true,
        default: '',
      },
      preset: {
        type: String,
        default: 'normal',
        validator(value) {
          return ['small', 'large'].includes(value)
        },
      },
      size: {
        type: Number,
        default: null,
      },
    },
    setup: (props) => {
      const state = reactive({
        initial: computed(() => {
          return props.username.charAt(0)
        }),
        avatarSize: computed(() => {
          if (!props.size) {
            let size = 100
            switch (props.preset) {
              case 'small':
                size = 48
                break
              case 'large':
                size = 155
                break
              default:
                break
            }

            return size
          }
          return props.size
        }),
        altText: computed(() => {
          return `profile image for ${props.username}`
        }),
      })

      return {
        ...toRefs(state),
      }
    },
  })
</script>

<template>
  <div
    class="avatar"
    :style="{
      '--avatar-size':
        typeof avatarSize === 'number' ? `${avatarSize}px` : avatarSize,
      backgroundColor: !src ? 'var(--avatar-color)' : 'transparent',
    }"
  >
    <sb-image
      v-if="src"
      :src.prop="src"
      :alt.prop="altText"
      :width.prop="avatarSize"
      :height.prop="avatarSize"
      :lazy.prop="false"
    ></sb-image>
    <div v-else class="initial">
      <span>{{ initial }}</span>
    </div>
    <div class="functional">
      <slot name="functional"></slot>
    </div>
  </div>
</template>

<style>
  :host {
    --avatar-border-radius: 50%;
    --avatar-initial-color: #fffcf2;
    --avatar-color: #0f787e;
  }

  .avatar {
    position: relative;
    display: flex;
    width: var(--avatar-size);
    height: var(--avatar-size);
    align-items: center;
    background: transparent;
    border-radius: var(--avatar-border-radius);
    color: var(--avatar-initial-color);
    text-align: center;
  }

  .avatar sb-image {
    --image-border-radius: 50%;
    --image-width: var(--avatar-size);
    --image-height: var(--avatar-size);
    --image-object-fit: cover;
    --image-object-position: center;
  }

  .initial {
    display: flex;
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: center;
    font-size: calc(var(--avatar-size) / 2); /* 50% of parent */
    font-weight: 700;
    line-height: 1;
    text-align: center;
    text-transform: uppercase;
    user-select: none;
  }
</style>
