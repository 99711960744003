<script>
  import { defineComponent } from 'vue'

  export default defineComponent({
    name: 'ToastNotification',
    props: {
      show: { type: Boolean, default: false },
      success: { type: Boolean, default: false },
      error: { type: Boolean, default: false },
    },
    setup: () => {},
  })
</script>

<template>
  <transition name="fade">
    <div v-show="show" class="toast-notification">
      <div
        v-if="success || error"
        class="notification-highlight"
        :data-success="success"
        :data-error="error"
      ></div>
      <div class="message">
        <slot></slot>
      </div>
    </div>
  </transition>
</template>

<style>
  :host {
    --toast-background-color: #fffcf2;
    --toast-text-color: #575656;
    --toast-highlight-success-color: #27825c;
    --toast-highlight-error-color: #a12027;
    --toast-top-position: min(1.5vh, 55px);
    --toast-left-position: 50%;
    --toast-bottom-position: auto;
    --toast-right-position: auto;
    --toast-transform: translateX(-50%);
  }

  .toast-notification {
    position: absolute;
    z-index: 10;
    top: var(--toast-top-position);
    right: var(--toast-right-position);
    bottom: var(--toast-bottom-position);
    left: var(--toast-left-position);
    width: 100%;
    max-width: calc(100% - 4.5rem);
    padding: 0.375rem 1.125rem;
    background-color: var(--toast-background-color);
    border-radius: 4px;
    box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.15);
    color: var(--toast-text-color);
    font-size: 0.875rem;
    transform: var(--toast-transform);
  }

  .message {
    position: relative;
  }

  .notification-highlight {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 3px;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
  }

  .notification-highlight[data-error='true'] {
    background-color: var(--toast-highlight-error-color);
  }

  .notification-highlight[data-success='true'] {
    background-color: var(--toast-highlight-success-color);
  }

  /* transitiion styles */
  .fade-enter-active,
  .fade-leave-active {
    transition: opacity 0.5s;
  }

  .fade-enter-from,
  .fade-leave-to {
    opacity: 0;
  }

  @media all and (min-width: 800px) {
    .toast-notification {
      width: auto;
    }
  }
</style>
