<script>
  import { defineComponent } from 'vue'

  export default defineComponent({
    name: 'Card',
    props: {},
    setup: () => {},
  })
</script>

<template>
  <div class="card">
    <slot></slot>
  </div>
</template>

<style>
  :host {
    --card-background-color: #fffcf2;
    --card-border: none;
    --card-border-radius: 10px;
    --card-box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.15);
    --card-max-width: max-content;
  }

  .card {
    display: flex;
    max-width: var(--card-max-width);
    height: 100%;
    box-sizing: border-box;
    border: var(--card-border);
    background: var(--card-background-color);
    border-radius: var(--card-border-radius);
    box-shadow: var(--card-box-shadow);
  }
</style>
