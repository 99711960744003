<script>
  import {
    defineComponent,
    reactive,
    ref,
    toRefs,
    onMounted,
    provide,
  } from 'vue'

  export default defineComponent({
    name: 'Tabs',
    props: {
      tabPosition: {
        type: String,
        default: 'horizontal',
        validator(value) {
          return ['horizontal', 'vertical'].includes(value)
        },
      },
      tabSeparation: {
        type: String,
        default: 'start',
        validator(value) {
          return ['start', 'end', 'center', 'space-between'].includes(value)
        },
      },
    },
    setup: () => {
      const state = reactive({
        selectedIndex: 0,
        tabs: [],
        count: 0,
      })

      const tabsContainer = ref(null)

      // provide the state to the individual tab components
      provide('tabs', state)

      const selectTab = (i) => {
        state.selectedIndex = i
      }

      const getTabTitle = (tab) => {
        return tab.querySelector("[slot='title']").innerHTML
      }

      onMounted(() => {
        // query for the slotted tabs content
        // native components dont support vues scoped slots so context.slots can't be used
        const tabNodes = tabsContainer.value
          .querySelector('slot')
          .assignedNodes()
        state.tabs = tabNodes
        selectTab(0)
      })

      return {
        ...toRefs(state),
        tabsContainer,
        selectTab,
        getTabTitle,
      }
    },
  })
</script>

<template>
  <div
    ref="tabsContainer"
    class="tabs"
    :data-tab-position="tabPosition"
    :data-tab-separation="tabSeparation"
  >
    <div class="tabs-header">
      <button
        v-for="(tab, index) in tabs"
        :key="index"
        type="button"
        :data-selected="index === selectedIndex"
        :style="{ '--tab-count': tabs.length }"
        class="tab"
        @click="selectTab(index)"
        v-html="getTabTitle(tab)"
      ></button>
    </div>
    <!-- default slot used for the tab panes -->
    <div class="tab-panes">
      <slot></slot>
    </div>
  </div>
</template>

<style>
  /* import base tabs styles from css library */
  @import '@storyboard-fm/storyboard-css/blocks/tabs.css';

  :host {
    --tabs-border-color: #c4c4c4;
    --tabs-selected-border-color: #000000;
    --tabs-icon-color: #575656;
  }
</style>
