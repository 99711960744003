<script>
  import { defineComponent, reactive, computed, toRefs, onMounted } from 'vue'

  export default defineComponent({
    name: 'DataTable',
    props: {
      rows: {
        type: Array,
        default: () => [],
      },
      columns: {
        type: Array,
        default: () => [],
      },
      pageSize: {
        type: Number,
        default: 9,
      },
    },
    setup: (props) => {
      const state = reactive({
        ascending: false,
        pageSize: props.pageSize,
        maxHeight: computed(() => {
          return `${props.pageSize * 90 + 40}px`
        }),
        localColumns: computed(() => {
          if (props.rows.length == 0 && props.columns.length == 0) {
            return []
          }
          return props.columns && props.columns.length > 0
            ? props.columns
            : Object.keys(props.rows[0])
        }),
        localRows: computed(() => {
          if (state.viewableRows.length == 0) {
            return []
          }
          let rows = state.viewableRows
          return rows
            .sort((a, b) => {
              if (a[state.sortColumn] > b[state.sortColumn]) {
                return state.ascending ? 1 : -1
              } else if (a[state.sortColumn] < b[state.sortColumn]) {
                return state.ascending ? -1 : 1
              }
              return 0
            })
            .filter((row) => {
              let rowValues = Object.values(row)
              if (state.keyword) {
                let filterMatches = rowValues.find(
                  (val) =>
                    val
                      .toString()
                      .toLowerCase()
                      .indexOf(state.keyword.toLowerCase()) !== -1
                )
                if (filterMatches) return row
              } else {
                return row
              }
            })
        }),
        sortColumn: '',
        viewableRows: [],
      })

      const isHTML = (str) => {
        return /<[^<]+>/.test(str)
      }

      const sortTable = (col) => {
        if (state.sortColumn === col) {
          state.ascending = !state.ascending
        } else {
          state.ascending = true
          state.sortColumn = col
        }
      }

      const pageChange = (event) => {
        state.viewableRows = event.detail[0]
      }

      onMounted(() => {
        state.sortColumn =
          props.columns && props.columns.length ? props.columns[0] : ''
      })

      return {
        ...toRefs(state),
        sortTable,
        isHTML,
        pageChange,
      }
    },
  })
</script>

<template>
  <div class="data-table" :style="{ '--table-max-height': maxHeight }">
    <table class="table">
      <thead>
        <tr>
          <th
            v-for="(col, index) in localColumns"
            :key="index"
            scope="col"
            @click="sortTable(col)"
          >
            <div class="column-header">
              {{ col }}
              <div class="[ icon baseline ]">
                <mdi-arrow-up-down-bold />
              </div>
            </div>
          </th>
        </tr>
      </thead>
      <tbody v-if="localRows.length > 0" class="table-body">
        <tr v-for="(row, rowIndex) in localRows" :key="rowIndex">
          <td
            v-for="(col, colIndex) in localColumns"
            :key="colIndex"
            :data-title="col"
          >
            <div v-if="isHTML(row[col])" v-html="row[col]"></div>
            <div v-else>{{ row[col] }}</div>
          </td>
        </tr>
      </tbody>
      <div v-else class="no-results">
        <div class="message"><slot name="no-results">No Results</slot></div>
      </div>
    </table>
  </div>
  <div class="table-controls">
    <div v-show="localRows.length > 0" class="[ pages-navigation footer ]">
      <sb-pagination
        :items.prop="rows"
        :items-per-page.prop="pageSize"
        @paginated="pageChange"
        ><div slot="label">Displaying Episodes</div></sb-pagination
      >
    </div>
  </div>
</template>

<style>
  @import '@storyboard-fm/storyboard-css/blocks/icon.css';
  @import '@storyboard-fm/storyboard-css/blocks/datatable.css';

  :host {
    --table-header-icon-color: #565657;
    --table-header-border-bottom: 1px solid #c4c4c4;
    --table-header-background: #fffcf2;
    --table-header-hover-color: #c4c4c4;
    --table-row-odd-background: #fffcf2;
    --table-row-even-background: #fffcf2;
    --table-row-border: 1px solid #c4c4c4;
    --table-row-border-radius: 3px;
    --table-body-background: #fffcf2;
    --table-body-cell-min-height: 32px;
    --table-body-cell-padding: 0.875rem 1.25rem;
    --table-body-cell-border-top: 0.5px solid #c4c4c4;
    --table-body-cell-border-bottom: 0.5px solid #c4c4c4;
    --table-controls-padding: 1rem 0;
    --table-controls-border-top: 1px solid #c4c4c4;
  }
</style>
