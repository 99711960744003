// IndexedDB wrapper class
export class IndexedDB {
  // connect to IndexedDB database
  constructor(dbName, dbVersion, dbUpgrade) {
    return new Promise((resolve, reject) => {
      // connection object
      this.db = null

      // no support
      if (!('indexedDB' in window)) reject('not supported')

      // open database
      const dbOpen = indexedDB.open(dbName, dbVersion)

      if (dbUpgrade) {
        // database upgrade event
        dbOpen.onupgradeneeded = (e) => {
          dbUpgrade(dbOpen.result, e.oldVersion, e.newVersion)
        }
      }

      dbOpen.onsuccess = () => {
        this.db = dbOpen.result
        resolve(this)
      }

      dbOpen.onerror = (e) => {
        reject(`IndexedDB error: ${e.target.errorCode}`)
      }
    })
  }

  // store item
  set(storeName, name, value) {
    return new Promise((resolve, reject) => {
      // new transaction
      const transaction = this.db.transaction(storeName, 'readwrite'),
        store = transaction.objectStore(storeName)

      // write record
      store.put(value, name)

      transaction.oncomplete = () => {
        resolve(true) // success
      }

      transaction.onerror = () => {
        reject(transaction.error) // failure
      }
    })
  }

  // append items to an array store
  append(storeName, name, value) {
    return new Promise((resolve, reject) => {
      // new transaction
      const transaction = this.db.transaction(storeName, 'readwrite'),
        store = transaction.objectStore(storeName),
        request = store.get(name)

      request.onsuccess = () => {
        let array = request.result // success
        array = array.concat(value)
        store.put(array, name)
      }

      transaction.oncomplete = () => {
        resolve(true) // success
      }

      transaction.onerror = () => {
        reject(transaction.error) // failure
      }
    })
  }

  // get named item
  get(storeName, name) {
    return new Promise((resolve, reject) => {
      // new transaction
      const transaction = this.db.transaction(storeName, 'readonly'),
        store = transaction.objectStore(storeName),
        // read record
        request = store.get(name)

      request.onsuccess = () => {
        resolve(request.result) // success
      }

      request.onerror = () => {
        reject(request.error) // failure
      }
    })
  }

  getAll(storeName) {
    return new Promise((resolve, reject) => {
      // new transaction
      const transaction = this.db.transaction(storeName, 'readonly'),
        store = transaction.objectStore(storeName),
        // read record
        request = store.getAll()

      request.onsuccess = () => {
        resolve(request.result) // success
      }

      request.onerror = () => {
        reject(request.error) // failure
      }
    })
  }

  delete(storeName, name) {
    return new Promise((resolve, reject) => {
      // new transaction
      const transaction = this.db.transaction(storeName, 'readwrite'),
        store = transaction.objectStore(storeName),
        // delete the record
        request = store.delete(name)

      request.onsuccess = () => {
        resolve(request.result) // success
      }

      request.onerror = () => {
        reject(request.error) // failure
      }
    })
  }
}
