<script>
  import { defineComponent, onMounted, ref } from 'vue'
  import { useForm } from 'vee-validate'

  export default defineComponent({
    name: 'CustomForm',
    props: {
      id: {
        type: String,
        required: true,
        default: '',
      },
      action: {
        type: String,
        default: null,
      },
      name: {
        type: String,
        required: true,
        default: '',
      },
      netlify: {
        type: Boolean,
        default: false,
      },
      validationSchema: {
        type: Object,
        default: () => {},
      },
      initialValues: {
        type: Object,
        default: () => {},
      },
      autoReset: {
        type: Boolean,
        default: true,
      },
    },
    emits: ['submit'],
    setup: (props, { emit, expose }) => {
      const form = ref(null)
      const formFields = ref({})
      const { handleSubmit, resetForm } = useForm({
        validationSchema: props.validationSchema,
        initialValues: props.initialValues,
      })

      const onSubmit = handleSubmit((values) => {
        if (props.action) {
          // set formdata to send with request
          formFields.value = values
          // trigger native form submit
          form.value.submit()
        } else {
          // emit the submit event and the values to the submit function
          // data will be available via event.detail
          emit('submit', values)

          if (props.autoReset) {
            // reset the form and the field values to their initial values
            reset()
          }
        }
      })

      const reset = () => {
        if (props.initialValues) {
          resetForm(props.initialValues)
        } else {
          resetForm()
        }
        console.log(`${props.name} form reset`)
      }

      onMounted(() => {
        // bind a formdata event to ensure the data is sent from the form correctly
        if (props.action) {
          form.value.addEventListener('formdata', ({ formData }) => {
            for (let key in formFields.value) {
              if (Object.prototype.hasOwnProperty.call(formFields.value, key)) {
                formData.append(key, formFields.value[key])
              }
            }
          })
        }
      })

      // expose the reset function be called manually if necessary
      expose({
        reset,
      })

      return {
        form,
        onSubmit,
        reset,
      }
    },
  })
</script>

<template>
  <div class="form-wrapper">
    <form
      :id="id"
      ref="form"
      :name="name"
      class="form"
      :action="action"
      method="post"
      :data-netlify="netlify"
      :data-netlify-honeypot="netlify ? 'bot-field' : null"
      @submit="onSubmit"
    >
      <div class="header">
        <slot name="header"></slot>
      </div>
      <!-- honeypot field to prevent bot spam -->
      <input v-if="netlify" type="hidden" name="form-name" :value="name" />
      <div class="inputs">
        <slot name="inputs"></slot>
      </div>
      <div class="buttons">
        <slot name="buttons"></slot>
      </div>
    </form>
  </div>
</template>

<style>
  /* import base input styles from css library */
  @import '@storyboard-fm/storyboard-css/blocks/form.css';

  :host {
    --form-background: #fffcf2;
    --form-padding: 0;
    --form-spacing: 1rem;
    --form-place-content: center flex-start;
    --form-button-alignment: flex-start;
    --form-filter: drop-shadow(0px 3px 10px rgba(0, 0, 0, 0.15));
    --form-border: none;
    --form-border-radius: 0;
  }
</style>
