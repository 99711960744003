import { defineRule, configure } from 'vee-validate'
import rules from '@vee-validate/rules'

export const registerInputValidationRules = () => {
  // define all of vee-validates available rules
  Object.keys(rules).forEach((rule) => {
    defineRule(rule, rules[rule])
  })

  // configure messages for fields where no custom rule is set
  configure({
    generateMessage: (context) => {
      let lowerCaseField = fieldName(context.field)
      let message = `Your ${lowerCaseField} is not a valid format. Enter a valid ${lowerCaseField}`
      if (context.rule.name === 'required') {
        message = `Enter your ${lowerCaseField}. Your ${lowerCaseField} is required.`
      }
      if (context.rule.name === 'numeric') {
        message = `${lowerCaseField} may only contain numeric values.`
      }
      if (context.rule.name === 'email') {
        message =
          'We don’t recognize this email format. Enter a valid email address.'
      }
      return message
    },
  })

  defineRule('password', (value) => {
    // Field is empty, should pass
    if (!value || !value.length) {
      return true
    }

    if (value.length < 8) {
      return 'Length must be greater than 8 characters.'
    }

    return true
  })

  defineRule('confirmed', (value, [other]) => {
    // Field is empty, should pass
    if (!value || !value.length) {
      return true
    }

    if (value !== other) {
      return `Passwords do not match`
    }

    return true
  })

  defineRule('company', (value, [target], context) => {
    // Field is empty, should pass
    if (!value || !value.length) {
      return true
    }

    // let regexp = /(?:\s*[a-zA-Z0-9,_\.\077\0100\*\+\&\#\'\~\;\-\!\@\;]{2,}\s*)*/
    let regexp =
      /^(?!\s)(?!.*\s$)(?=.*[a-zA-Z0-9])[a-zA-Z0-9 ;~*#+@.\-'~?!]{2,}$/
    if (!regexp.test(value)) {
      let lowerCaseField = fieldName(context.field)
      return `We don't recognize this ${lowerCaseField} format. Enter a valid ${lowerCaseField}.`
    }

    return true
  })

  defineRule('name', (value, [target], context) => {
    // Field is empty, should pass
    if (!value || !value.length) {
      return true
    }

    let regexp = /^([ \u00c0-\u01ffa-zA-Z'-])+$/
    if (!regexp.test(value)) {
      let lowerCaseField = fieldName(context.field)
      return `We don't recognize this ${lowerCaseField} format. Enter a valid ${lowerCaseField}.`
    }

    return true
  })

  defineRule('phone', (value) => {
    // Field is empty, should pass
    if (!value || !value.length) {
      return true
    }

    // regular expression that validates against north american and international phone numbers
    let regexp =
      /^((\+\d{1,3}(-|\s|.)?\(?\d\)?(-|\s|.)?\d{1,5})|(\(?\d{2,6}\)?))(-|\s|.)?(\d{3,4})(-|\s|.)?(\d{4})((\s?x|\s?ext|\s?extension)\d{1,5}){0,1}$/
    if (!regexp.test(value)) {
      return "We don't recognize this phone number format. Enter a valid phone number."
    }

    return true
  })

  defineRule('usernameAvailable', async (value, [validator]) => {
    let result = null
    if (validator.constructor.name === 'AsyncFunction') {
      result = await validator(value)
    } else {
      result = validator(value)
    }
    return result ? true : 'This username is unavailable.'
  })
}

const fieldName = (field) => {
  return field
    .replace(/[_-]/g, ' ')
    .replace(/([a-z])([A-Z])/g, '$1 $2')
    .toLowerCase()
}
