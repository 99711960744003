import { reactive, toRefs, computed, nextTick } from 'vue'
import { tabbable } from 'tabbable'

export function useFocusTrap() {
  const state = reactive({
    returnElement: null,
    focused: 0,
    firstFocusable: computed(() =>
      state.tabbableElements.length > 0 ? state.tabbableElements[0] : null
    ),
    lastFocusable: computed(() =>
      state.tabbableElements.length > 0
        ? state.tabbableElements[state.tabbableElements.length - 1]
        : null
    ),
    tabbableElements: [],
  })

  const getTabbableElements = (container) => {
    let element = container.value ?? container
    state.tabbableElements = tabbable(element, {
      displayCheck: 'non-zero-area',
      getShadowRoot: true,
    })
  }

  const moveFocus = (index) => {
    if (state.tabbableElements[index]) {
      state.tabbableElements[index].focus()
    }
  }

  const focusFirst = () => {
    moveFocus(0)
  }

  const focusNext = () => {
    if (state.focused === state.tabbableElements.length - 1) {
      state.firstFocusable.focus()
      state.focused = 0
    } else {
      state.focused += 1
      moveFocus(state.focused)
    }
  }

  const focusPrevious = () => {
    if (state.focused === 0) {
      state.lastFocusable.focus()
      state.focused = state.tabbableElements.length - 1
    } else {
      state.focused -= 1
      moveFocus(state.focused)
    }
  }

  const focusReturnElement = () => {
    // if the return element is a custom storyboard element, specifically a button then focus back to it
    if (state.returnElement?.tagName.includes('SB-')) {
      nextTick(() => {
        if (state.returnElement._instance.exposed) {
          state.returnElement._instance.exposed.focus()
        } else {
          state.returnElement?.focus()
        }
      })
    } else {
      nextTick(() => {
        state.returnElement?.focus()
      })
    }
  }

  return {
    ...toRefs(state),
    getTabbableElements,
    moveFocus,
    focusFirst,
    focusNext,
    focusPrevious,
    focusReturnElement,
  }
}
