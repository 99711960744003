<script>
  import {
    defineComponent,
    ref,
    reactive,
    toRefs,
    computed,
    nextTick,
    watch,
  } from 'vue'
  import { useElementSize } from '@vueuse/core'
  import { useField } from 'vee-validate'

  export default defineComponent({
    name: 'CustomSelect',
    props: {
      name: {
        type: String,
        required: true,
      },
      options: {
        type: Array,
        default: () => [],
        required: true,
      },
      tabIndex: {
        type: Number,
        required: false,
        default: 0,
      },
      option: {
        type: Object,
        default: () => {
          return { label: '', value: '' }
        },
      },
      placeholder: {
        type: String,
        default: 'Select',
      },
      label: {
        type: String,
        default: '',
      },
      rules: {
        type: [String, Object],
        default: () => null,
      },
      required: {
        type: Boolean,
        default: false,
      },
      disabled: {
        type: Boolean,
        default: false,
      },
      searchable: {
        type: Boolean,
        default: false,
      },
      inputMaxWidth: {
        type: String,
        default: '100%',
      },
      optionsMaxWidth: {
        type: String,
        default: 'max-content',
      },
      inputWidth: {
        type: String,
        default: '',
      },
      inputIcon: {
        type: Boolean,
        default: true,
      },
      errorIcon: {
        type: Boolean,
        default: true,
      },
      filterBy: {
        type: Function,
        default: (option, searchText) => {
          if (option.label && option.value) {
            return (
              option.label
                .toString()
                .toLowerCase()
                .includes(searchText.toString().toLowerCase()) ||
              option.value
                .toString()
                .toLowerCase()
                .includes(searchText.toString().toLowerCase())
            )
          }

          if (option.label) {
            return option.label
              .toString()
              .toLowerCase()
              .includes(searchText.toString().toLowerCase())
          }

          if (option.value) {
            option.value
              .toString()
              .toLowerCase()
              .includes(searchText.toString().toLowerCase())
          }
          return option
            .toString()
            .toLowerCase()
            .includes(searchText.toString().toLowerCase())
        },
      },
    },
    emits: ['change'],
    setup: (props, { emit }) => {
      const select = ref(null)
      const search = ref(null)
      const icon = ref(null)
      const optionsList = ref(null)
      const { width: optionsWidth } = useElementSize(optionsList)
      const noOptions = {
        label: 'No Results',
        value: null,
        disabled: true,
      }
      const {
        value: selected,
        errorMessage,
        meta,
      } = useField(props.name, props.rules || undefined)

      const state = reactive({
        open: false,
        activeOptionIndex: computed(() => {
          return state.filteredOptions.findIndex((x) => {
            return JSON.stringify(x) === JSON.stringify(props.option)
          })
        }),
        searchText: '',
        filteredOptions: computed(() => {
          if (props.searchable) {
            // add filter function
            let filteredList = props.options.filter((option) =>
              props.filterBy(option, state.searchText)
            )
            return filteredList.length === 0 ? [noOptions] : filteredList
          }
          return props.options
        }),
        prevOptionIndex: computed(() => {
          const next = state.activeOptionIndex - 1
          return next >= 0 ? next : state.filteredOptions.length - 1
        }),
        nextOptionIndex: computed(() => {
          const next = state.activeOptionIndex + 1
          return next <= state.filteredOptions.length - 1 ? next : 0
        }),
        selectedValue: computed(() => {
          return state.filteredOptions[state.activeOptionIndex]
            ? state.filteredOptions[state.activeOptionIndex].label
            : ''
        }),
        focusedOption: null,
        focusedOptionIndex: computed(() => {
          return state.filteredOptions.findIndex((x) => {
            return JSON.stringify(x) === JSON.stringify(state.focusedOption)
          })
        }),
        tabKeyPressed: false,
        width: props.inputWidth,
      })

      watch(optionsWidth, (curr, prev) => {
        if (!props.inputWidth) {
          if (curr > prev) {
            state.width = optionsWidth.value
          }
        }
      })

      watch(
        () => state.selectedValue,
        (curr) => {
          selected.value = curr
        }
      )

      const handleInputBlur = (e) => {
        if (select.value.contains(e.relatedTarget)) return
        hideOptions()
      }

      const toggleOptions = () => {
        if (props.disabled) return
        state.open ? hideOptions() : showOptions()
      }

      const showOptions = () => {
        if (props.disabled) return
        state.open = true
        if (!props.searchable) {
          nextTick(() => {
            optionsList.value.focus()
          })
        } else {
          nextTick(() => {
            search.value.focus()
          })
        }
        // set our initial value to the props value if selected already
        if (props.option) return
      }

      const hideOptions = () => {
        state.open = false
      }

      const reset = () => {
        hideOptions()
        if (props.searchable) {
          nextTick(() => {
            icon.value.focus()
          })
        }
      }

      const handleOption = (option) => {
        if (option.disabled) return
        if (option) {
          emit('change', option)
        }
        if (props.searchable) {
          nextTick(() => {
            state.searchText = state.selectedValue
          })
        }
        reset()
      }

      const setupFocus = () => {
        if (props.option) return
        emit('change', props.option)
      }

      const handleFocusTrap = () => {
        state.open = true
        nextTick(() => {
          search.value.focus()
        })
      }

      const selectPrevOption = () => {
        if (props.disabled) return
        let prevOption = state.filteredOptions[state.prevOptionIndex]
        state.focusedOption = prevOption
        emit('change', prevOption)
      }

      const selectNextOption = () => {
        if (props.disabled) return
        let nextOption = state.filteredOptions[state.nextOptionIndex]
        state.focusedOption = nextOption
        emit('change', nextOption)
      }

      const deleteSearch = () => {
        state.focusedOption = null
        emit('change', null)
        showOptions()
      }

      const deleteSelection = () => {
        state.searchText = ''
        deleteSearch()
      }

      return {
        ...toRefs(state),
        select,
        search,
        icon,
        optionsList,
        optionsWidth,
        toggleOptions,
        showOptions,
        handleInputBlur,
        handleOption,
        setupFocus,
        handleFocusTrap,
        reset,
        selectPrevOption,
        selectNextOption,
        deleteSearch,
        deleteSelection,
        errorMessage,
        meta,
      }
    },
  })
</script>

<template>
  <div
    ref="select"
    class="select-input"
    :data-error="!!errorMessage && meta.touched"
    :disabled="disabled"
    @keydown.tab="tabKeyPressed = true"
    @blur.capture="handleInputBlur"
  >
    <div class="field-label">
      <div v-if="label" class="field-name">
        {{ label }}
        <span v-if="!required" class="optional"> (Optional)</span>
      </div>
      <div class="optional-field-element">
        <slot name="label-additional"></slot>
      </div>
    </div>
    <div
      class="search-input"
      :style="{ width: `${width}px`, maxWidth: inputMaxWidth }"
    >
      <input
        v-if="searchable"
        ref="search"
        v-model="searchText"
        class="input"
        :aria-disabled="disabled"
        :data-open="open"
        :tabindex="tabIndex"
        :style="{ width: `${width}px`, maxWidth: inputMaxWidth }"
        :placeholder="placeholder"
        @keyup.up.prevent="selectPrevOption"
        @keyup.down.prevent="selectNextOption"
        @keyup.esc.prevent="hideOptions"
        @keyup.enter.prevent="handleOption(focusedOption)"
        @keyup.delete.prevent="deleteSearch"
        @focus="showOptions"
      />
      <input
        v-else
        ref="search"
        class="input"
        :aria-disabled="disabled"
        :data-open="open"
        :tabindex="tabIndex"
        readonly
        :style="{ width: `${width}px`, maxWidth: inputMaxWidth }"
        :placeholder="placeholder"
        :value="selectedValue"
        @click="toggleOptions"
        @keydown.enter.prevent
        @keyup.up.down.prevent="showOptions"
        @keyup.up.prevent="selectPrevOption"
        @keyup.down.prevent="selectNextOption"
        @keyup.esc.prevent="hideOptions"
      />
      <div v-if="errorIcon" class="[ field-icon invalid ]">
        <mdi-alert-circle-outline />
      </div>
      <div v-if="searchable && searchText && selectedValue">
        <sb-button
          class="[ icon delete-selection-icon ]"
          variation="icon-only"
          @click="deleteSelection"
          ><mdi-close-circle-outline aria-hidden="true" focusable="false" />
          <span class="visually-hidden">Delete selection</span></sb-button
        >
      </div>
      <sb-button
        v-if="inputIcon"
        ref="icon"
        class="icon"
        variation="icon-only"
        @click="toggleOptions"
      >
        <mdi-chevron-down aria-hidden="true" focusable="false" />
        <span class="visually-hidden"
          >{{ open ? 'Hide' : 'Show' }} {{ label }} list</span
        >
      </sb-button>
    </div>
    <!-- Focus trap for iOS keyboard navigation. -->
    <input
      v-if="!tabKeyPressed"
      aria-hidden="true"
      class="visually-hidden"
      @focus="handleFocusTrap"
    />
    <ul
      ref="optionsList"
      role="listbox"
      class="[ options ] [ flow ]"
      tabindex="-1"
      :data-hide="!open"
      :aria-expanded="open"
      :style="{
        maxWidth: searchText || selectedValue ? `${width}px` : optionsMaxWidth,
      }"
      @focus="setupFocus"
      @keyup.up.prevent="selectPrevOption"
      @keyup.down.prevent="selectNextOption"
      @keydown.up.down.prevent
      @keydown.enter.esc.prevent="reset"
    >
      <li
        v-for="(option, i) of filteredOptions"
        :key="i"
        class="option"
        role="option"
        :aria-selected="activeOptionIndex === i"
        :data-has-focus="focusedOptionIndex === i"
        @click="handleOption(option)"
      >
        {{ option.label }}
      </li>
    </ul>
    <p v-show="errorMessage && meta.touched" class="error-message">
      {{ errorMessage }}
    </p>
  </div>
</template>

<style>
  @import '@storyboard-fm/storyboard-css/blocks/select.css';
  @import '@storyboard-fm/storyboard-css/utilities/visually-hidden.css';

  :host {
    --select-input-spacing: 1rem 0 0 0;
    --select-input-padding: 0.625rem 4.25rem 0.625rem 1rem;
    --select-input-background: #fffcf2;
    --select-input-height: auto;
    --select-input-min-width: 85px;
    --select-input-text: #000000;
    --select-input-label-font-weight: 500;
    --select-input-text-align: left;
    --select-input-optional: #575656;
    --select-input-border: 1px solid #575656;
    --select-input-border-radius: 0;
    --select-input-icon-color: #575656;
    --select-input-focus: #c4c4c4;
    --select-focus-border-top: 1px solid #575656;
    --select-focus-border-right: 1px solid #575656;
    --select-focus-border-bottom: 1px solid #575656;
    --select-focus-border-left: 1px solid #575656;
    --select-box-shadow: none;
    --select-focus-outline: 2px solid #000000;
    --select-focus-outline-offset: 0.25rem;
    --select-option-padding: 0.5rem 1rem;
    --select-options-spacing: 0.5rem 0 0 0;
    --select-options-max-height: 200px;
    --select-options-border: none;
    --select-options-box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.15);
    --select-options-open-border-radius: 0;
    --select-input-error: #a12027;
    --select-input-error-font-weight: 500;
    --select-input-error-label: #000000;
    --select-input-error-background: transparent;
  }
</style>
