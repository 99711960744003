import { reactive, toRefs, computed } from 'vue'
import Uppy from '@uppy/core'
import Tus from '@uppy/tus'
import { formatBytes } from '../utils/files'

export default function useUppy(options, tusOptions) {
  const state = reactive({
    uploading: false,
    uploadComplete: false,
    fileTotalBytes: 0,
    file: null,
    preview: null,
    uploadedFileBytes: 0,
    uploadFileSize: computed(() => {
      return formatBytes(state.fileTotalBytes)
    }),
    progress: computed(() =>
      state.uploadedFileBytes
        ? Math.floor(state.uploadedFileBytes / state.fileTotalBytes) * 100
        : 0
    ),
    uploader: new Uppy(options),
  })

  state.uploader.use(Tus, tusOptions)

  const addFile = (name, file, type, meta = {}) => {
    try {
      state.uploader.addFile({
        name: name,
        type: type,
        data: file,
        meta: meta,
        source: 'Local',
      })
    } catch (e) {
      console.error(e)
    }
  }

  const uploadFile = () => {
    console.log('Starting upload')
    state.uploader.upload()
  }

  const removeFile = () => {
    console.log('Removing file')
    state.uploader.removeFile(state.file.id)
  }

  const resetUploadingStatus = () => {
    state.uploadComplete = false
    state.uploadedFileBytes = 0
    state.fileTotalBytes = 0
  }

  state.uploader.on('file-added', (file) => {
    console.log('Added file', file)
    state.file = file
  })

  state.uploader.on('file-removed', (file) => {
    console.log('Removed file', file)
    state.file = null
    state.preview = null
  })

  state.uploader.on('thumbnail:generated', (file, preview) => {
    // update the file with the preview
    state.file = file
    state.preview = preview
  })

  state.uploader.on('upload', (data) => {
    // data object consists of `id` with upload ID and `fileIDs` array
    // with file IDs in current upload
    // data: { id, fileIDs }
    state.uploadComplete = false
    state.uploading = true
    console.log(`Starting upload ${data.id} for files ${data.fileIDs}`)
  })

  state.uploader.on('upload-progress', (file, progress) => {
    // file: { id, name, type, ... }
    // progress: { uploader, bytesUploaded, bytesTotal }
    if (state.fileTotalBytes === 0) state.fileTotalBytes = progress.bytesTotal

    state.uploadedFileBytes = progress.bytesUploaded

    console.log(file.id, progress.bytesUploaded, progress.bytesTotal)
  })

  state.uploader.on('upload-success', (file, response) => {
    state.uploadComplete = true
    state.uploading = false
    console.debug(`Uploaded ${file.name} to ${response.uploadURL}`)
  })

  state.uploader.on('upload-error', (file, error) => {
    console.log('error with file:', file.id)
    console.log('error message:', error)
  })

  return {
    ...toRefs(state),
    addFile,
    removeFile,
    uploadFile,
    resetUploadingStatus,
  }
}
