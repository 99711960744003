<script>
  import { defineComponent, ref, reactive, toRefs, watch, nextTick } from 'vue'
  import { onClickOutside } from '@vueuse/core'
  import { useFocusTrap } from '../features/focusTrap'

  export default defineComponent({
    props: {},
    setup: (props) => {
      const menu = ref(null)
      const state = reactive({
        expanded: false,
        focusTrap: useFocusTrap(),
      })

      const toggleMenu = () => {
        state.expanded = !state.expanded
      }

      const close = () => {
        state.expanded = false
        state.focusTrap.focusReturnElement()
      }

      const focusNext = (e) => {
        if (state.expanded) {
          e.preventDefault()
          state.focusTrap.focusNext()
        }
      }

      const focusPrevious = (e) => {
        if (state.expanded) {
          e.preventDefault()
          state.focusTrap.focusPrevious()
        }
      }

      onClickOutside(menu, () => {
        if (state.expanded) close()
      })

      watch(
        () => state.expanded,
        (curr) => {
          if (curr) {
            state.focusTrap.returnElement = document.activeElement.shadowRoot
              ? document.activeElement.shadowRoot.activeElement
              : document.activeElement
            nextTick(() => {
              state.focusTrap.getTabbableElements(menu.value)
              state.focusTrap.focusFirst()
            })
          }
        }
      )

      return {
        ...toRefs(state),
        menu,
        toggleMenu,
        close,
        focusNext,
        focusPrevious,
      }
    },
  })
</script>

<template>
  <div
    ref="menu"
    class="expandable-menu"
    :data-expanded="expanded"
    @keydown.prevent.esc="close"
    @keydown.tab.exact="focusNext"
    @keydown.shift.tab="focusPrevious"
  >
    <button class="control" @click="toggleMenu">
      <slot name="menu-label"></slot>
    </button>
    <div class="menu" :aria-expanded="expanded">
      <slot name="menu-content"></slot>
    </div>
  </div>
</template>

<style>
  @import '@storyboard-fm/storyboard-css/blocks/expandable-menu.css';

  :host {
    --expandable-menu-control-background: transparent;
    --expandable-menu-background: #fffcf2;
    --expandable-menu-border: 1px solid #575656;
    --expandable-menu-active-border: 1px solid #575656;
    --expandable-menu-border-radius: 0;
    --expandable-menu-active-color: #692ea4;
  }
</style>
