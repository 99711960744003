import { ref, isRef, watch, onMounted, onUnmounted } from 'vue'

export const SCROLL_LOCK_CLASS = 'no-scroll'

export function useScrollLock(
  selectorElements,
  lockedClass,
  targetElement = null
) {
  const elements = wrap(selectorElements)
  let scrollY

  if (!lockedClass || !isString(lockedClass)) {
    lockedClass = SCROLL_LOCK_CLASS
  }

  const locked = ref(false)

  watch(
    locked,
    (l) => {
      if (lockedClass) {
        if (!elements.value) return
        elements.value.classList.toggle(lockedClass, l)
      }
    },
    {
      immediate: true,
    }
  )

  const lock = () => {
    scrollY = window.scrollY
    locked.value = true
    if (targetElement) {
      targetElement.addEventListener('pointermove', preventDefault)
      targetElement.addEventListener('touchmove', preventDefault)
    }
  }
  const unlock = () => {
    locked.value = false
    if (targetElement) {
      targetElement.removeEventListener('pointermove', preventDefault)
      targetElement.removeEventListener('touchmove', preventDefault)
    }
    window.scrollTo(0, scrollY)
  }
  const remove = () => {
    if (elements.value) {
      elements.value.classList.remove(lockedClass)
    }
    window.removeEventListener('resize', syncHeight)
    if (targetElement) {
      targetElement.removeEventListener('pointermove', preventDefault)
      targetElement.removeEventListener('touchmove', preventDefault)
    }
  }

  onMounted(() => {
    window.addEventListener('resize', syncHeight)
    if (isString(selectorElements)) {
      elements.value = document.querySelector(selectorElements)
    }
  })

  onUnmounted(() => remove())

  return {
    isScrollLocked: locked,
    lockScroll: lock,
    unlockScroll: unlock,
    clearLockScroll: remove,
  }
}

function syncHeight() {
  document.documentElement.style.setProperty(
    '--window-inner-height',
    `${window.innerHeight}px`
  )
}

function preventDefault(e) {
  e.preventDefault()
}

// helpers
function wrap(o) {
  return isRef(o) ? o : ref(o)
}
const isString = (val) => typeof val === 'string'
