<script>
  import { defineComponent, computed, ref, nextTick } from 'vue'

  export default defineComponent({
    name: 'CustomButton',
    props: {
      type: {
        type: String,
        default: 'button',
        validator(value) {
          return ['button', 'submit', 'reset'].includes(value)
        },
      },
      variation: {
        type: String,
        default: 'rounded',
        validator(value) {
          return ['rounded', 'icon', 'icon-only', 'text', 'link'].includes(
            value
          )
        },
      },
      disabled: {
        type: Boolean,
        default: false,
      },
      associatedForm: {
        type: String,
        default: '',
      },
      tooltip: {
        type: String,
        default: null,
      },
    },
    emits: ['click'],
    setup: (props, { expose }) => {
      const button = ref()
      const handleClick = () => {
        if (props.associatedForm) {
          // get the associated form element from the shadow root of the same id
          const form = document
            .getElementById(props.associatedForm)
            .shadowRoot.getElementById(props.associatedForm)

          if (form) {
            switch (props.type) {
              case 'reset':
                form.reset()
                break
              default:
                if (form.requestSubmit) {
                  form.requestSubmit()
                } else {
                  // workaround for browsers that dont support requestSubmit, i.e Safari
                  let submitter = document.createElement('input')
                  submitter.type = 'submit'
                  submitter.hidden = true
                  form.appendChild(submitter)
                  submitter.click()
                  form.removeChild(submitter)
                }
                break
            }
          }
        }
      }

      const btnClasses = computed(() => {
        let classes = ''
        switch (props.variation) {
          case 'rounded':
            classes = 'btn-rounded'
            break
          case 'icon':
            classes = 'icon-btn'
            break
          case 'icon-only':
            classes = '[ icon-btn icon-only ]'
            break
          case 'text':
            classes = 'btn-text'
            break
          case 'link':
            classes = 'link'
            break
          default:
            classes = 'btn'
            break
        }

        return classes
      })

      const focus = () => {
        nextTick(() => {
          button.value.focus()
        })
      }

      expose({ focus })

      return {
        button,
        handleClick,
        btnClasses,
      }
    },
  })
</script>

<template>
  <button
    ref="button"
    :class="btnClasses"
    :type="type"
    :disabled="disabled"
    :aria-describedby="tooltip"
    @click="handleClick"
  >
    <!-- default slot, i.e. button label content, this can be a string or an html block to render with an icon -->
    <div v-if="tooltip" :id="tooltip" class="tooltip" role="tooltip">
      <slot name="tooltip"></slot>
    </div>
    <slot></slot>
  </button>
</template>

<style>
  /* import base button styles from css library */
  @import '@storyboard-fm/storyboard-css/blocks/button.css';
  @import '@storyboard-fm/storyboard-css/blocks/link.css';

  :host {
    --btn-spacing: 0;
    --btn-rounded-padding: 1rem 1.5rem;
    --btn-text-padding: 1rem 0.5rem;
    --btn-height: auto;
    --btn-min-width: 15ch;
    --btn-background: #000000;
    --btn-background-active: transparent;
    --btn-background-disabled: #c4c4c4;
    --btn-border: 2px solid var(--btn-background);
    --btn-border-disabled: 2px solid transparent;
    --btn-border-radius: 10em;
    --btn-text: #ffffff;
    --btn-text-min-width: 10ch;
    --btn-text-active: #000000;
    --btn-text-disabled: #575656;
    --btn-font-weight: 700;
    --btn-text-transform: uppercase;
    --btn-icon-color: #575656;
    --btn-icon-active-color: #000000;
    --btn-outline: 2px solid #8c98a4;
    --btn-outline-offset: 0.25rem;
    --btn-focus-border: 2px solid var(--btn-background);
    --btn-box-shadow: none;
    --btn-text-outline: 2px solid #000000;
    --btn-icon-outline: 2px solid #000000;
    --btn-tooltip-background: #fffcf2;
    --btn-tooltip-text: #575656;
    --link-text: #692ea4;
    --link-font-weight: 400;
  }
</style>
