import { reactive, toRefs } from 'vue'
import { IndexedDB } from '../shared/indexed-db'

export function useIndexedDB() {
  const state = reactive({
    dbName: '',
    dbVersion: 1,
    db: null,
    storeName: 'state',
  })

  const dbConnect = async () => {
    state.db =
      state.db ||
      (await new IndexedDB(state.dbName, state.dbVersion, (db, oldVersion) => {
        // upgrade database

        switch (oldVersion) {
          case 0: {
            db.createObjectStore(state.storeName)
          }
        }
      }))
    return state.db
  }

  const set = async (name, value) => {
    const db = await dbConnect()
    await db.set(state.storeName, name, value)
  }

  const append = async (name, value) => {
    const db = await dbConnect()
    await db.append(state.storeName, name, value)
  }

  const get = async (name) => {
    // database fetch
    const db = await dbConnect()

    return await db.get(state.storeName, name)
  }

  const getAll = async () => {
    // database fetch
    const db = await dbConnect()

    return await db.getAll(state.storeName)
  }

  const deleteValue = async (name) => {
    // database fetch
    const db = await dbConnect()

    return await db.delete(state.storeName, name)
  }

  return {
    ...toRefs(state),
    set,
    append,
    get,
    getAll,
    deleteValue,
  }
}
