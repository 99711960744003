<script>
  import {
    defineComponent,
    ref,
    reactive,
    toRefs,
    onMounted,
    computed,
  } from 'vue'
  import ThumbnailGenerator from '@uppy/thumbnail-generator'
  import { useDevice } from '../features/deviceDetector'

  export default defineComponent({
    name: 'UploadAvatar',
    props: {
      options: {
        type: Object,
        default: () => {
          return {
            id: 'avatar-upload',
            restrictions: {
              maxFileSize: 5000000,
              maxNumberOfFiles: 1,
              minNumberOfFiles: 1,
              allowedFileTypes: ['.jpg', '.jpeg', '.png', '.webp'],
            },
          }
        },
      },
      tusOptions: {
        type: Object,
        default: () => {
          return { withCredentials: true }
        },
      },
      includeThumbnail: {
        type: Boolean,
        default: true,
      },
    },
    setup: (props, { expose }) => {
      const upload = ref(null)
      const dragDrop = ref(null)
      let inActiveTimeout = null
      const state = reactive({
        uppy: null,
        avatarImage: computed(() => (state.uppy ? state.uppy.file : null)),
        active: false,
        device: useDevice(),
        errorMessage: '',
        preview: computed(() => (state.uppy ? state.uppy.preview : null)),
        dragDropEnabled: computed(
          () => !state.device.ios && !state.device.android
        ),
        mergedTusOptions: computed(() => {
          // if we already have a withCredentials value set in the tusOptions, return those
          if (props.tusOptions['withCredentials']) {
            return props.tusOptions
          }
          // otherwise append a withCredentials property to the tusOptions
          return { ...props.tusOptions, withCredentials: true }
        }),
      })

      const addFile = (file) => {
        state.errorMessage = ''
        try {
          state.uppy.addFile(file.name, file, file.type, file.meta)
        } catch (err) {
          if (err.isRestriction) {
            // handle restrictions
            console.log('Restriction error:', err)
            state.errorMessage = err
          } else {
            // handle other errors
            console.error(err)
            state.errorMessage =
              'Something went wrong. Try adding your file again.'
          }
        }
      }

      const removeFile = () => {
        state.errorMessage = ''
        try {
          state.uppy.removeFile()
          state.uppy.resetUploadingStatus()
        } catch (err) {
          // handle other errors
          console.error(err)
          state.errorMessage = err
        }
      }

      const uploadFile = () => {
        state.errorMessage = ''
        try {
          state.uppy.uploadFile()
        } catch (err) {
          // handle other errors
          console.error(err)
          state.errorMessage = err
        }
      }

      const setActive = () => {
        state.active = true
        clearTimeout(inActiveTimeout)
      }

      const setInactive = () => {
        inActiveTimeout = setTimeout(() => {
          state.active = false
        }, 50)
      }

      const handleDrop = (event) => {
        setInactive()
        console.log(event.dataTransfer.files)

        let file =
          event.dataTransfer.files.length > 0
            ? event.dataTransfer.files[0]
            : null
        if (file) {
          addFile(file)
        }
      }

      const handleBrowse = (event) => {
        let file = event.target.files.length > 0 ? event.target.files[0] : null
        if (file) {
          addFile(file)
        }
      }

      onMounted(() => {
        // set the exposed object from the upload component to the local state variable
        if (upload.value) {
          state.uppy = upload.value._instance.exposed.uppy
          if (props.includeThumbnail) {
            state.uppy.uploader.use(ThumbnailGenerator, {
              thumbnailWidth: 200,
              thumbnailType: 'image/png',
              waitForThumbnailsBeforeUpload: false,
            })
          }
        }
      })

      expose({ uploadFile })

      return {
        ...toRefs(state),
        upload,
        dragDrop,
        setActive,
        setInactive,
        handleDrop,
        handleBrowse,
        addFile,
        removeFile,
      }
    },
  })
</script>

<template>
  <sb-upload
    ref="upload"
    :options.prop="options"
    :tus-options.prop="mergedTusOptions"
  >
    <div
      ref="dragDrop"
      class="upload-avatar"
      :data-active="active"
      @dragenter.prevent="setActive"
      @dragover.prevent="setActive"
      @dragleave.prevent="setInactive"
      @drop.prevent="handleDrop"
    >
      <div v-if="includeThumbnail && preview" class="upload-preview">
        <sb-image
          :src="preview"
          alt="preview of selected image"
          :width.prop="200"
          height="auto"
        />
        <sb-button
          v-if="uppy && (!uppy.uploading || !uppy.uploadComplete)"
          variation="icon-only"
          @click="removeFile"
          ><mdi-close-circle-outline
        /></sb-button>
      </div>
      <div v-else>
        <div class="upload-controls">
          <mdi-image-outline />
          <div v-if="dragDropEnabled" class="upload-instructions">
            <div>Drag and drop your image here or</div>
            <input
              id="upload-file-input"
              type="file"
              accept="image/*"
              class="upload-file-input"
              @change="handleBrowse"
            />
            <label class="upload-input-label" for="upload-file-input"
              >browse for file</label
            >
          </div>
          <!-- input limited to image file uploads on devices that do not support drag and drop -->
          <div v-else class="upload-instructions">
            <input
              id="upload-file-input"
              type="file"
              accept="image/*"
              class="upload-file-input"
              @change="handleBrowse"
            />
            <label class="upload-input-label" for="upload-file-input"
              >Browse your photo album</label
            >
          </div>
        </div>
      </div>
      <!-- temporary until we have a full design -->
      <div v-if="uppy" class="upload-status">
        <div v-if="uppy.uploading" class="progress-bar">
          <progress :value="uppy.progress" max="100"></progress>
          <div class="[ label ] [ font-bold ]">{{ uppy.progress }}%</div>
        </div>
        <div v-if="uppy.uploadComplete" class="upload-success">
          Avatar Uploaded!
        </div>
      </div>
    </div>
    <div v-show="errorMessage" class="error-message">
      {{ errorMessage }}
    </div>
  </sb-upload>
</template>

<style>
  @import '@storyboard-fm/storyboard-css/blocks/upload.css';
  @import '@storyboard-fm/storyboard-css/blocks/icon.css';

  :host {
    --upload-avatar-max-width: 375px;
    --upload-avatar-background: #fffcf2;
    --upload-avatar-active-background: #dddbd5;
    --upload-avatar-box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.15);
    --upload-avatar-border-radius: 10px;
    --upload-avatar-icon-color: #575656;
    --upload-input-text-color: #692ea4;
    --upload-input-font-weight: 500;
    --upload-input-padding: 0 0.325rem;
    --upload-progress-color: #b17be0;
    --upload-success-color: #229967;
    --upload-error-color: #d9000c;
  }
</style>
