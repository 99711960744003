<script>
  import { defineComponent, toRefs, reactive, computed, watch } from 'vue'

  export default defineComponent({
    name: 'Pagination',
    props: {
      items: {
        type: Array,
        default: () => [],
      },
      itemsPerPage: {
        type: Number,
        default: 25,
      },
    },
    emits: ['paginated'],
    setup: (props, { emit }) => {
      const state = reactive({
        currentPage: 1,
        paginatedItems: computed(() => {
          let items = state.items
          let sliced = items.slice(
            (state.currentPage - 1) * state.itemsPerPage,
            state.currentPage * state.itemsPerPage
          )
          return sliced
        }),
        items: computed(() => props.items),
        itemsPerPage: computed(() => props.itemsPerPage),
        firstItem: computed(() => {
          if (state.totalItems > 0) {
            return state.currentPage > 1
              ? (state.currentPage - 1) * state.itemsPerPage
              : 1
          }
          return 0
        }),
        lastItem: computed(() => {
          if (state.totalItems > 0) {
            const lastItemIndex = state.currentPage * state.itemsPerPage
            return lastItemIndex > state.totalItems
              ? state.totalItems
              : lastItemIndex
          }
          return 0
        }),
        totalItems: computed(() => state.items.length || 0),
        numberOfPages: computed(() =>
          Math.ceil((state.items.length || 0) / state.itemsPerPage)
        ),
        pageOptions: computed(() => {
          let options = []
          for (let i = 1; i <= state.numberOfPages; i++) {
            let option = {
              label: `${i} of ${state.numberOfPages}`,
              value: i,
            }
            options.push(option)
          }
          return options
        }),
        selectedPage: computed(() => {
          return {
            label: `${state.currentPage} of ${state.numberOfPages}`,
            value: state.currentPage,
          }
        }),
      })

      const setCurrent = (event) => {
        state.currentPage = event.detail[0].value
      }

      const previous = () => {
        if (state.currentPage === 1) return
        state.currentPage -= 1
      }

      const next = () => {
        if (state.currentPage >= state.numberOfPages) return
        state.currentPage += 1
      }

      watch(
        () => state.items,
        (current) => {
          if (current.length < state.itemsPerPage) {
            state.currentPage = 1
          }
        }
      )

      watch(
        () => state.paginatedItems,
        (curr) => {
          emit('paginated', curr)
        },
        {
          deep: true,
          immediate: true,
        }
      )

      return {
        ...toRefs(state),
        setCurrent,
        next,
        previous,
      }
    },
  })
</script>

<template>
  <div class="pagination">
    <div class="display-info">
      <div class="items-displayed">
        <div>
          <slot name="label">Displaying Items </slot>
        </div>
        <div>
          <b> {{ firstItem }} - {{ lastItem }}</b> of <b>{{ totalItems }}</b> in
          total
        </div>
      </div>
      <div class="controls">
        <sb-button
          part="previousButton"
          class="previous"
          :data-hidden="currentPage === 1"
          variation="icon-only"
          @click="previous"
          ><mdi-chevron-left
        /></sb-button>
        <div class="page-select">
          Page
          <sb-select
            part="pageOptionsSelect"
            name="page-options"
            :options.prop="pageOptions"
            :option.prop="selectedPage"
            :input-icon.prop="false"
            @change="setCurrent"
          ></sb-select>
        </div>
        <sb-button
          part="nextButton"
          class="next"
          :data-hidden="currentPage === numberOfPages"
          variation="icon-only"
          @click="next"
          ><mdi-chevron-right
        /></sb-button>
      </div>
    </div>
  </div>
</template>

<style>
  @import '@storyboard-fm/storyboard-css/blocks/pagination.css';

  :host {
    --pagination-item-alignment: flex-end;
  }
</style>
