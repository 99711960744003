<script>
  import { defineComponent, computed, ref } from 'vue'

  export default defineComponent({
    name: 'SkeletonBox',
    props: {
      maxWidth: {
        // The default maxiumum width is 100%.
        default: 100,
        type: Number,
      },
      minWidth: {
        // Lines have a minimum width of 80%.
        default: 80,
        type: Number,
      },
      height: {
        // Make lines the same height as text.
        default: '1em',
        type: String,
      },
      width: {
        // Make it possible to define a fixed
        // width instead of using a random one.
        default: null,
        type: String,
      },
    },
    setup: (props) => {
      const box = ref(null)

      const computedWidth = computed(() => {
        return (
          props.width ||
          `${Math.floor(
            Math.random() * (props.maxWidth - props.minWidth) + props.minWidth
          )}%`
        )
      })

      return {
        box,
        computedWidth,
      }
    },
  })
</script>

<template>
  <div>
    <span
      ref="box"
      :style="{ height, width: computedWidth }"
      class="skeleton-box"
    />
  </div>
</template>

<style>
  :host {
    --skeleton-background: #dddbdd;
    --skeleton-animation-background: linear-gradient(
      90deg,
      rgba(255, 255, 255, 0) 0,
      rgba(255, 255, 255, 0.2) 20%,
      rgba(255, 255, 255, 0.5) 60%,
      rgba(255, 255, 255, 0)
    );
  }

  .skeleton-box {
    position: relative;
    display: inline-block;
    overflow: hidden;
    box-sizing: border-box;
    background-color: var(--skeleton-background);
    vertical-align: middle;
  }

  .skeleton-box::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    animation: shimmer 2s infinite;
    background-image: var(--skeleton-animation-background);
    content: '';
    transform: translateX(-100%);
  }

  @keyframes shimmer {
    100% {
      transform: translateX(100%);
    }
  }
</style>
