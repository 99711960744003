<script>
  import { defineComponent, ref, reactive, toRefs, watch, computed } from 'vue'
  import { useDebounceFn } from '@vueuse/core'
  import { useField } from 'vee-validate'

  export default defineComponent({
    name: 'CustomInput',
    props: {
      type: {
        type: String,
        default: 'text',
        validator(value) {
          return [
            'text',
            'email',
            'password',
            'number',
            'tel',
            'textarea',
            'hidden',
          ].includes(value)
        },
      },
      modelValue: {
        type: String,
        default: '',
      },
      name: {
        type: String,
        required: true,
      },
      label: {
        type: String,
        default: '',
      },
      successMessage: {
        type: String,
        default: '',
      },
      placeholder: {
        type: String,
        default: null,
      },
      rules: {
        type: [String, Object],
        default: () => null,
      },
      required: {
        type: Boolean,
        default: false,
      },
      disabled: {
        type: Boolean,
        default: false,
      },
      autoComplete: {
        type: String,
        default: 'off',
      },
      errorIcon: {
        type: Boolean,
        default: true,
      },
    },
    emits: ['changed'],
    setup: (props, { emit }) => {
      const {
        value: inputValue,
        errorMessage,
        handleBlur,
        handleChange,
        meta,
      } = useField(props.name, props.rules || undefined, {
        initialValue: props.modelValue,
        validateOnValueUpdate: false,
      })

      const touched = ref(false)
      const input = ref(null)

      const state = reactive({
        passwordRevealed: false,
      })

      watch(inputValue, (val) => {
        if (!touched.value) {
          inputValue.value = val
        }
      })

      const updateInternalValue = (event, shouldValidate = true) => {
        touched.value = true
        updateValue(event.target.value, shouldValidate)
      }

      const updateValue = useDebounceFn((value, shouldValidate = true) => {
        touched.value = false
        handleChange(value, shouldValidate)
        emit('changed', value)
      }, 300)

      const validationListeners = computed(() => {
        // If the field is valid or have not been validated yet
        // lazy
        if (!errorMessage.value) {
          return {
            blur: handleBlur,
            change: updateInternalValue,
            // disable `shouldValidate` to avoid validating on input
            input: (e) => updateInternalValue(e, false),
          }
        }

        // Aggressive
        return {
          blur: updateInternalValue,
          change: updateInternalValue,
          input: updateInternalValue,
        }
      })

      const togglePassword = () => {
        const type =
          input.value.getAttribute('type') === 'password' ? 'text' : 'password'
        input.value.setAttribute('type', type)
        state.passwordRevealed = !state.passwordRevealed
      }

      return {
        ...toRefs(state),
        input,
        validationListeners,
        updateInternalValue,
        handleChange,
        handleBlur,
        errorMessage,
        inputValue,
        meta,
        togglePassword,
      }
    },
  })
</script>

<template>
  <label
    :for="name"
    class="input-box"
    :data-error="!!errorMessage && meta.touched"
    :data-success="meta.valid"
    :data-type="type"
  >
    <div class="field-label">
      <div v-if="label" class="field-name">
        {{ label }}
        <span v-if="!required" class="optional"> (Optional)</span>
      </div>
      <div class="optional-field-element">
        <slot name="label-additional"></slot>
      </div>
    </div>
    <div class="input-field">
      <div class="field-icon">
        <slot name="field-icon"></slot>
      </div>
      <textarea
        v-if="type === 'textarea'"
        :id="name"
        ref="input"
        :name="name"
        :type="type"
        :value="inputValue"
        :placeholder="placeholder"
        :required="required"
        :autocomplete="autoComplete"
        v-on="validationListeners"
      />
      <input
        v-else
        :id="name"
        ref="input"
        :name="name"
        :type="type"
        :value="inputValue"
        :placeholder="placeholder"
        :required="required"
        :autocomplete="autoComplete"
        v-on="validationListeners"
      />
      <div v-if="errorIcon" class="[ field-icon invalid ]">
        <mdi-alert-circle-outline />
      </div>
      <div v-show="type === 'password'" class="[ field-icon reveal ]">
        <sb-button variation="icon-only" @click="togglePassword">
          <mdi-eye-outline v-if="passwordRevealed" />
          <mdi-eye-off-outline v-else />
        </sb-button>
      </div>
    </div>
    <slot name="additional"> </slot>
    <p
      v-show="(errorMessage && meta.touched) || meta.valid"
      class="help-message"
    >
      {{ errorMessage || successMessage }}
    </p>
  </label>
</template>

<style>
  /* import base input styles from css library */
  @import '@storyboard-fm/storyboard-css/blocks/input.css';

  :host {
    --input-spacing: 1rem 0 0 0;
    --input-padding: 0 0 0.25rem 0;
    --input-height: auto;
    --input-text: #000000;
    --input-background: transparent;
    --input-label-font-weight: 500;
    --input-icon: #575656;
    --input-box-sizing: content-box;
    --input-border-top: none;
    --input-border-right: none;
    --input-border-bottom: 1px solid #575656;
    --input-border-left: none;
    --input-border-radius: 0;
    --input-padding-focused: 0 0 calc(0.25rem - 1px);
    --input-border-focused: 2px solid #000000;
    --input-border-focus-margin: -2px -2px 0;
    --input-box-shadow: none;
    --input-optional: #575656;
    --input-success: #27825c;
    --input-error: #a12027;
    --input-error-font-weight: 500;
    --input-error-label: #000000;
    --input-error-background: transparent;
  }
</style>
