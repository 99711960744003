<script>
  import {
    defineComponent,
    onMounted,
    reactive,
    toRefs,
    computed,
    ref,
    nextTick,
    onUpdated,
    watch,
  } from 'vue'
  import waveformAvgChunker from '../utils/waveformAvgChunker'
  import { roundToTwo } from '../utils/math'
  import { durationFormat } from '../utils/time'

  export default defineComponent({
    props: {
      colors: {
        type: Object,
        default: () => {
          return {
            waveform: '#c4c4c4',
            playing: '#b17be0',
            hover: '#692ea4',
          }
        },
      },
      // in seconds
      trackDuration: {
        type: Number,
        default: 0,
        required: true,
      },
      // needs to be set to an object to be reactive
      trackStatus: {
        type: Object,
        default: () => {
          return { playing: false, seek: 0 }
        },
      },
      peaksData: {
        type: Object,
        default: () => {
          return {}
        },
      },
      peaksUrl: {
        type: String,
        default: '',
      },
      waveformSize: {
        type: Object,
        default: () => {
          return {
            height: 200,
            width: 1000,
            scale: 1,
          }
        },
      },
    },
    setup: (props) => {
      const canvasContainer = ref(null)
      const canvas = ref(null)

      const state = reactive({
        data: [],
        peaks: [],
        ratio: 1,
        normalized: [],
        chunkedData: computed(() =>
          waveformAvgChunker(
            state.normalized,
            Math.floor(
              state.containerSize.width / (state.pointWidth + state.pointMargin)
            )
          )
        ),
        pointWidth: 5,
        pointMargin: 4,
        trackProgress: 0,
        playingPoint: computed(() => {
          let point = 0
          if (props.trackStatus.seek > 0) {
            point =
              (state.trackProgress * state.containerSize.width) /
              100 /
              (state.pointWidth + state.pointMargin)
          }
          return point
        }),
        hoverXCoord: 0,
        hoverPositionTime: 0,
        containerSize: {
          width: 0,
          height: 0,
        },
        devicePixelRatio: computed(() =>
          window.devicePixelRatio ? window.devicePixelRatio : 1
        ),
      })

      const chunkArray = (arr, size) =>
        arr.length > size
          ? [arr.slice(0, size), ...chunkArray(arr.slice(size), size)]
          : [arr]

      const initializePeaksData = (peaks) => {
        if (peaks.data && peaks.data.length) {
          state.data = peaks.data
          state.peaks = state.data.filter((point) => point >= 0)
          // split the peaks array into chunks to prevent a maximum callstack error
          let splitPeaks = chunkArray(state.peaks, 5000)
          let maxPeaks = []
          splitPeaks.forEach((peaksArr) => {
            maxPeaks.push(Math.max(...peaksArr))
          })
          state.ratio = Math.max(...maxPeaks) / 100
          state.normalized = state.peaks.map((point) =>
            Math.round(point / state.ratio)
          )
        } else {
          console.log('No peaks data provided!')
        }
      }

      const pointCoordinates = ({
        index,
        pointWidth,
        pointMargin,
        canvasHeight,
        amplitude,
      }) => {
        // calculate the points height based on the canvas height,
        // for taller points we multiple the height by 3 and check that its not taller than the canvas height
        let pointHeight =
          Math.round((amplitude / 100) * canvasHeight) *
          props.waveformSize.scale
        if (pointHeight > canvasHeight) pointHeight = canvasHeight
        const verticalCenter = Math.round((canvasHeight - pointHeight) / 2)
        return [
          index * (pointWidth + pointMargin), // x starting point
          canvasHeight - pointHeight - verticalCenter, // y starting point
          pointWidth, // width
          pointHeight, // height
        ]
      }

      const paintCanvas = ({
        canvasRef,
        waveformData,
        canvasHeight,
        pointWidth,
        pointMargin,
        playingPoint,
        hoverXCoord,
      }) => {
        const ref = canvasRef
        const ctx = ref.getContext('2d')

        // update the canvas height/width to the device pixel ratio
        ref.width = state.containerSize.width * state.devicePixelRatio
        ref.height = state.containerSize.height * state.devicePixelRatio

        // On every canvas update, erase the canvas before painting
        // If you don't do this, you'll end up stacking waveforms and waveform
        // colors on top of each other
        ctx.clearRect(0, 0, ref.width, ref.height)

        // ensure the drawing of the canvas is scale
        ctx.scale(state.devicePixelRatio, state.devicePixelRatio)

        // scale everything down use CSS to fit the waveform in the container
        ref.style.width = `${state.containerSize.width}px`
        ref.style.height = `${state.containerSize.height}px`

        waveformData.forEach((p, i) => {
          const coordinates = pointCoordinates({
            index: i,
            pointWidth: pointWidth,
            pointMargin: pointMargin,
            canvasHeight,
            amplitude: p,
          })
          ctx.roundRect(...coordinates, 10)
          const withinHover = hoverXCoord > coordinates[0]
          const alreadyPlayed = i < playingPoint
          if (withinHover) {
            ctx.fillStyle = props.colors.hover
          } else if (alreadyPlayed) {
            ctx.fillStyle = props.colors.playing
          } else {
            ctx.fillStyle = props.colors.waveform
          }
          ctx.fill()
        })
      }

      CanvasRenderingContext2D.prototype.roundRect = function (
        x,
        y,
        width,
        height,
        radius
      ) {
        if (width < 2 * radius) radius = width / 2
        if (height < 2 * radius) radius = height / 2
        this.beginPath()
        this.moveTo(x + radius, y)
        this.arcTo(x + width, y, x + width, y + height, radius)
        this.arcTo(x + width, y + height, x, y + height, radius)
        this.arcTo(x, y + height, x, y, radius)
        this.arcTo(x, y, x + width, y, radius)
        this.closePath()
        return this
      }

      const setDefaultX = () => {
        state.hoverXCoord = 0
      }

      const handleMouseMove = (e) => {
        const xCoord = e.clientX - canvas.value.getBoundingClientRect().left
        state.hoverXCoord = xCoord
        if (props.trackDuration && props.trackDuration > 0) {
          let trackPosition = xCoord / canvas.value.offsetWidth
          let trackHoverTime = roundToTwo(trackPosition * props.trackDuration)
          if (trackHoverTime > props.trackDuration) {
            trackHoverTime = roundToTwo(props.trackDuration)
          }
          state.hoverPositionTime = durationFormat(trackHoverTime)
        }
      }

      const paintWaveform = () => {
        if (canvas.value) {
          paintCanvas({
            canvasRef: canvas.value,
            waveformData: state.chunkedData,
            canvasHeight: state.containerSize.height,
            pointWidth: state.pointWidth,
            pointMargin: state.pointMargin,
            playingPoint: state.playingPoint,
            hoverXCoord: state.hoverXCoord,
          })
        }
      }

      const seekTrack = (e) => {
        const xCoord = e.clientX - canvas.value.getBoundingClientRect().left
        state.hoverXCoord = xCoord
      }

      const updateTrackProgress = () => {
        const clamp = (val, min, max) => Math.min(Math.max(val, min), max)
        const trackProgressPerc =
          (props.trackStatus.seek / props.trackDuration) * 100
        state.trackProgress = clamp(trackProgressPerc, 0, 100)
        requestAnimationFrame(updateTrackProgress)
      }

      const resize = () => {
        if (canvas.value) {
          // if we have a parent container width and height available,
          // use those to redraw a responsive waveform.
          // Otherwise use the supplied waveformSize props
          state.containerSize.width =
            canvasContainer.value.clientWidth === 0
              ? props.waveformSize.width
              : canvasContainer.value.clientWidth
          state.containerSize.height =
            canvasContainer.value.clientHeight === 0
              ? props.waveformSize.height
              : canvasContainer.value.clientHeight
        }
      }

      watch(
        () => props.trackStatus.seek,
        (seek, prevSeek) => {
          if (seek != prevSeek) paintWaveform()
        }
      )

      onMounted(async () => {
        // initialize repsonse waveform
        window.addEventListener('resize', () => {
          resize()
          paintWaveform()
        })

        if (props.peaksUrl) {
          await fetch(props.peaksUrl)
            .then((response) => {
              if (!response.ok) {
                throw new Error('HTTP error ' + response.status)
              }
              return response.json()
            })
            .then((peaks) => {
              console.log('loaded peaks!')
              initializePeaksData(peaks)
            })
            .catch((e) => {
              console.error('error', e)
            })
        } else {
          initializePeaksData(props.peaksData)
        }

        nextTick(() => {
          resize()
          paintWaveform()
          updateTrackProgress()
        })
      })

      onUpdated(() => {
        resize()
        paintWaveform()
      })

      return {
        ...toRefs(state),
        canvasContainer,
        canvas,
        setDefaultX,
        handleMouseMove,
        seekTrack,
        paintWaveform,
      }
    },
  })
</script>

<template>
  <div
    ref="canvasContainer"
    class="waveform-container"
    @blur="setDefaultX"
    @mouseout="setDefaultX"
    @mousemove="handleMouseMove"
  >
    <canvas
      id="waveform-canvas"
      ref="canvas"
      class="waveform"
      :height="containerSize.height"
      :width="containerSize.width"
      @click="seekTrack"
    >
    </canvas>
    <div
      v-if="trackDuration"
      class="timestamp"
      :style="{ left: `${hoverXCoord}px` }"
    >
      {{ hoverPositionTime }}
    </div>
  </div>
</template>

<style>
  .waveform-container {
    position: relative;
    display: flex;
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }

  canvas {
    position: relative;
  }

  canvas:hover + .timestamp {
    opacity: 1;
    visibility: visible;
  }

  .timestamp {
    position: absolute;
    top: -1rem;
    left: 0;
    width: max-content;
    box-sizing: border-box;
    padding: 0.25rem;
    border: 1px solid #c4c4c4;
    background: var(--color-bg-primary);
    border-radius: 4px;
    color: var(--color-text-primary);
    font-size: 0.875rem;
    line-height: initial;
    opacity: 0;
    text-align: center;
    transform: translate(-50%, -50%);
    transition: 0.3s opacity;
    visibility: hidden;
  }
</style>
