<script>
  import { defineComponent, ref } from 'vue'
  import useUppy from '../features/uppy'

  export default defineComponent({
    name: 'Upload',
    props: {
      options: {
        type: Object,
        default: () => {
          return {}
        },
      },
      tusOptions: {
        type: Object,
        default: () => {
          return {}
        },
      },
    },
    setup: (props, { expose }) => {
      const uppy = ref(useUppy(props.options, props.tusOptions))

      expose({ uppy })

      return {}
    },
  })
</script>

<template>
  <div class="upload">
    <slot></slot>
  </div>
</template>

<style>
  .upload {
    width: 100%;
    height: 100%;
  }
</style>
