<script>
  import {
    defineComponent,
    ref,
    reactive,
    toRefs,
    watch,
    nextTick,
    onBeforeUnmount,
  } from 'vue'
  import { useFocusTrap } from '../features/focusTrap'
  import { useScrollLock } from '../features/scrollLock'
  import { onClickOutside } from '@vueuse/core'

  export default defineComponent({
    name: 'Modal',
    props: {
      active: {
        type: Boolean,
        default: false,
      },
    },
    emits: ['close'],
    setup: (props, { emit }) => {
      const modal = ref(null)
      const closeBtn = ref(null)

      const state = reactive({
        focusTrap: useFocusTrap(),
        scrollLock: useScrollLock(
          document.querySelector('html'),
          'no-scroll',
          modal.value
        ),
      })

      const close = () => {
        emit('close')
        state.scrollLock.unlockScroll()
        state.focusTrap.focusReturnElement()
      }

      onClickOutside(modal, () => {
        if (props.active) {
          close()
        }
      })

      watch(
        () => props.active,
        (curr) => {
          if (curr) {
            state.scrollLock.lockScroll()
            state.focusTrap.returnElement = document.activeElement.shadowRoot
              ? document.activeElement.shadowRoot.activeElement
              : document.activeElement
            nextTick(() => {
              state.focusTrap.getTabbableElements(modal.value)
              closeBtn.value._instance.exposed.focus()
            })
          }
        }
      )

      onBeforeUnmount(async () => {
        await nextTick()
        state.scrollLock.clearLockScroll()
      })

      return {
        ...toRefs(state),
        modal,
        closeBtn,
        close,
      }
    },
  })
</script>

<template>
  <div
    :aria-hidden="!active"
    class="popover-modal"
    :data-state="active ? 'open' : 'closed'"
    @keydown.prevent.esc="close"
    @keydown.prevent.tab.exact="focusTrap.focusNext"
    @keydown.prevent.shift.tab="focusTrap.focusPrevious"
  >
    <transition name="fade">
      <div v-if="active" class="background" @click.self="close"></div>
    </transition>
    <div ref="modal" class="modal-content" role="dialog" aria-modal="true">
      <div class="close-btn">
        <sb-button
          ref="closeBtn"
          type="button"
          variation="icon-only"
          aria-label="Close modal"
          @click="close"
        >
          <mdi-close />
        </sb-button>
      </div>
      <slot></slot>
    </div>
  </div>
</template>

<style>
  /* import base modal styles from css library */
  @import '@storyboard-fm/storyboard-css/blocks/modal.css';
  @import '@storyboard-fm/storyboard-css/utilities/scroll-lock.css';

  :host {
    --modal-container-position: fixed;
    --modal-container-top-position: 0;
    --modal-container-left-position: 0;
    --modal-container-overflow: hidden;
    --modal-overlay-color: rgba(196, 196, 196, 0.4);
    --modal-content-background-color: #fffcf2;
    --modal-content-padding: 1.75rem;
    --modal-content-filter: drop-shadow(0px 3px 10px rgba(0, 0, 0, 0.15));
    --modal-overlay-position: absolute;
    --modal-content-position: relative;
    --modal-content-min-height: 100vh;
    --modal-content-height: 100%;
    --modal-content-top-position: 0;
    --modal-content-left-position: 0;
    --modal-close-btn-width: 1.2em;
    --modal-close-btn-height: 1.2em;
    --modal-close-btn-top-position: 1.75rem;
    --modal-close-btn-right-position: 1.75rem;
    --modal-backdrop-filter: blur(10px);
    --modal-content-transform: none;
    --modal-icon-color: #575656;
    --modal-icon-active-color: #000000;
  }

  @media all and (min-width: 640px) {
    :host {
      --modal-content-max-width: 100%;
      --modal-content-padding: 2rem 4rem 2rem 3rem;
      --modal-content-top-position: 20%;
      --modal-content-left-position: 50%;
      --modal-content-min-height: 250px;
      --modal-content-height: auto;
      --modal-content-border-radius: 0.5rem;
      --modal-close-btn-top-position: 2rem;
      --modal-close-btn-right-position: 2.5rem;
      --modal-content-transform: translate(
        calc(-1 * var(--modal-content-left-position)),
        calc(-1 * var(--modal-content-top-position))
      );
    }
  }

  @media all and (min-width: 800px) {
    :host {
      --modal-content-min-width: 600px;
      --modal-content-max-width: 775px;
      --modal-content-padding: 2rem 4rem;
    }
  }
</style>
