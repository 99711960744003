<script>
  import { defineComponent, computed } from 'vue'

  export default defineComponent({
    name: 'CustomImage',
    props: {
      src: {
        type: String,
        required: true,
        default: '',
      },
      alt: {
        type: String,
        default: '',
      },
      width: { type: [Number, String], default: 0 },
      height: { type: [Number, String], default: 0 },
      asBackground: { type: Boolean, default: false },
      lazy: {
        type: Boolean,
        default: true,
      },
    },
    setup: (props) => {
      const srcSet = computed(() => getImageSrcSet(props.src))
      const aspectRatio = computed(() => props.width / props.height)

      const getImageSrcSet = (imageUrl) => {
        function includesMatch(lookupValue, urlString) {
          const re = new RegExp(lookupValue, 'i')
          return urlString ? urlString.match(re) !== null : false
        }
        // prismic image resolver
        //if the url is not an svg, then set the dpr ratios for the image srcset
        if (includesMatch('images.prismic', imageUrl)) {
          return `${imageUrl}&dpr=1 1x, ${imageUrl}&dpr=2 2x`
        }

        //otherwise return empty
        return ''
      }

      return {
        srcSet,
        aspectRatio,
      }
    },
  })
</script>

<template>
  <img
    :data-background="asBackground"
    :src="src"
    :srcset="srcSet"
    :alt="alt"
    :width="width"
    :height="height"
    :loading="lazy ? 'lazy' : 'eager'"
    decoding="async"
    :style="{ '--aspect-ratio': aspectRatio }"
  />
</template>

<style>
  :host {
    --image-border: none;
    --image-border-radius: 0;
    --image-width: 100%;
    --image-height: 100%;
    --image-object-fit: fit;
    --image-object-position: unset;
    --image-padding: 0;
    --image-box-shadow: none;
  }

  img {
    display: flex;
    width: var(--image-width);
    max-width: 100%;
    height: var(--image-height);
    box-sizing: border-box;
    align-items: center;
    justify-content: center;
    padding: var(--image-padding);
    border: var(--image-border);
    aspect-ratio: var(--aspect-ratio);
    border-radius: var(--image-border-radius);
    box-shadow: var(--image-box-shadow);
    object-fit: var(--image-object-fit);
    object-position: var(--image-object-position);
  }

  @supports not (aspect-ratio: 1 / 1) {
    img::before {
      padding-top: 100%;
      content: '';
      float: left;
    }

    img::after {
      display: block;
      clear: both;
      content: '';
    }
  }

  /* fallback if the browser doesnt support object-fit */
  img[data-background='true'] {
    position: absolute;
    z-index: 0;
    top: 50%;
    left: 50%;
    display: inline-block;
    width: auto;
    min-width: 100vw;
    max-width: 1000%;
    height: auto;
    transform: translateX(-50%) translateY(-50%);
  }

  @supports (object-fit: cover) {
    img[data-background='true'] {
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: top center;
      transform: none;
    }
  }
</style>
