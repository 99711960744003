export function useDevice() {
  if (typeof window !== 'undefined') {
    const userAgent = window.navigator.userAgent.toLowerCase()
    const devicePixelRatio = window.devicePixelRatio || 1
    const iPadOS13Up =
      window.navigator.platform === 'MacIntel' &&
      window.navigator.maxTouchPoints > 1

    const find = (needle) => {
      return userAgent.indexOf(needle) !== -1
    }

    const windows = find('windows')
    const ipod = find('ipod')
    const ipad = find('ipad') || iPadOS13Up
    const iphone = !windows && find('iphone')
    const iphoneX =
      iphone &&
      devicePixelRatio === 3 &&
      window.screen.width === 375 &&
      window.screen.height === 812

    const iPhoneXR =
      iphone &&
      devicePixelRatio === 2 &&
      window.screen.width === 414 &&
      window.screen.height === 896

    const iPhoneXSMax =
      iphone &&
      devicePixelRatio === 3 &&
      window.screen.width === 414 &&
      window.screen.height === 896

    const ios = iphone || ipod || ipad
    const android = !windows && find('android')
    const androidPhone = android && find('mobile')
    const mobile = androidPhone || iphone || ipod

    const device = {
      windows,
      ipod,
      ipad,
      iphone,
      iphoneX,
      iPhoneXR,
      iPhoneXSMax,
      ios,
      mobile,
      android,
      androidPhone,
    }

    return device
  }
}
