<script>
  import { defineComponent, ref } from 'vue'
  import { useField } from 'vee-validate'

  export default defineComponent({
    name: 'Checkbox',
    props: {
      value: {
        type: [String, Boolean, Number],
        default: null,
      },
      name: {
        type: String,
        default: '',
        required: true,
      },
      disabled: {
        type: Boolean,
        default: false,
      },
      rules: {
        type: [String, Object],
        default: () => null,
      },
    },
    emits: ['changed'],
    setup: (props, { emit, expose }) => {
      const input = ref(null)
      const {
        checked,
        errorMessage,
        handleChange,
        meta,
        checkedValue,
        resetField,
      } = useField(props.name, props.rules || undefined, {
        type: 'checkbox',
        checkedValue: props.value,
      })

      const updateValue = (value) => {
        handleChange(value)
        // custom event to for reactive model changes
        emit('changed', { checked: checked.value, checkedValue })
      }

      const reset = () => {
        // create a new input event to trigger the necessary validation
        let event = new Event('input', {
          'bubbles': true,
        })
        input.value.checked = false
        input.value.dispatchEvent(event)
        resetField()
      }

      expose({ reset })

      return {
        input,
        checked, // readonly
        updateValue,
        handleChange,
        errorMessage,
        meta,
      }
    },
  })
</script>

<template>
  <label :for="name" class="checkbox-input" :data-error="!!errorMessage">
    <input
      ref="input"
      :id="name"
      aria-hidden="true"
      :disabled="disabled"
      :checked="checked"
      :value="value"
      type="checkbox"
      @input="updateValue"
    />
    <span class="checkbox-name"><slot></slot></span>
    <p v-show="errorMessage" class="help-message">
      {{ errorMessage }}
    </p>
  </label>
</template>

<style>
  @import '@storyboard-fm/storyboard-css/blocks/checkbox.css';

  :host {
    --checkbox-text-color: #000000;
    --checkbox-checked-color: #692ea4;
    --checkbox-check-color: #fffcf2;
    --checkbox-border-color: #000000;
    --checkbox-error-color: #a12027;
    --checkbox-outline: #8c98a4;
  }
</style>
